import { useEffect, useRef } from 'react';

type EventCallback = (event: Event) => void;

export const useEventListener = (
    eventName: string,
    handler: EventCallback,
    element = window
) => {
    const savedHandler = useRef<EventCallback>();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        const eventListener = (event: any) => {
            if (event && savedHandler && savedHandler.current) {
                (savedHandler as any).current(event);
            }
        };
        element.addEventListener(eventName, eventListener);

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
};
