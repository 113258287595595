import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSearchTerm } from 'components/hooks/search/useSearchTerm';
import { scrollUp } from 'core/utils/animations';
import { EXTERNAL_LINK_PROPS } from 'core/utils/link';
import { SearchNav } from './SearchMenu';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useActionTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { FeatureTracked } from 'models/tracking';
import { getConfiguration } from 'core/services/common/configuration/configurationLoader';

type SeeMoreLinkProps = {
    to: SearchNav;
    total: number;
    openInNew?: boolean;
    seeMoreLinkOnClick?: () => Promise<void>;
};

export const SeeMoreLink: React.FunctionComponent<SeeMoreLinkProps> = ({
    to,
    total,
    openInNew,
    seeMoreLinkOnClick,
}) => {
    const { t: translate } = useTranslation('service-list');
    const { getSearchRoute } = useSearchTerm();
    const onClickSeeMore = async () => {
        if (seeMoreLinkOnClick) {
            await seeMoreLinkOnClick();
        }
        scrollUp();
    };
    return (
        <p className="text-end mt-2">
            <Link
                to={getSearchRoute(to)}
                onClick={onClickSeeMore}
                {...(openInNew && EXTERNAL_LINK_PROPS)}
            >
                {translate('service-list:seeAll', { total })}{' '}
                <i className="icon icon-sm">arrow_forward</i>
            </Link>
        </p>
    );
};

export const SeeMoreLinkSgm: React.FunctionComponent<
    Omit<SeeMoreLinkProps, 'to'>
> = ({ total, openInNew }) => {
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();
    const trackActionCallback = useCallback(
        async () =>
            await trackAction(FeatureTracked.OpenSGMResults, actionTrackedInfo),
        [actionTrackedInfo, trackAction]
    );
    return (
        <SeeMoreLink
            to="sgmarkets"
            total={total}
            openInNew={openInNew}
            seeMoreLinkOnClick={trackActionCallback}
        />
    );
};

export const SeeMoreLinkOnIc: React.FC = () => {
    const { searchTerm } = useSearchTerm();
    const { t: translate } = useTranslation('service-list');
    const { trackAction } = useServiceTracking();
    const { seeAllInIc } = getConfiguration();
    const { actionTrackedInfo } = useActionTrackingContext();
    const trackActionCallback = useCallback(
        async () =>
            await trackAction(FeatureTracked.OpenIcSearch, actionTrackedInfo),
        [actionTrackedInfo, trackAction]
    );

    const onClickSeeMore = async () => {
        await trackActionCallback();
    };
    return (
        <p className="text-end mt-2">
            <a
                href={seeAllInIc.replace('{0}', searchTerm ?? '')}
                onClick={onClickSeeMore}
                {...EXTERNAL_LINK_PROPS}
            >
                {translate('service-list:seeAllInIc')}
                <i className="icon icon-sm">arrow_forward</i>
            </a>
        </p>
    );
};

export const SeeMoreLinkSgAll: React.FunctionComponent<
    Omit<SeeMoreLinkProps, 'to'>
> = ({ total, openInNew }) => {
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();
    const trackActionCallback = useCallback(
        async () =>
            await trackAction(
                FeatureTracked.OpenSGAllResults,
                actionTrackedInfo
            ),
        [actionTrackedInfo, trackAction]
    );
    return (
        <SeeMoreLink
            to="societe"
            total={total}
            openInNew={openInNew}
            seeMoreLinkOnClick={trackActionCallback}
        />
    );
};

export const SeeMoreLinkPerson: React.FunctionComponent<
    Omit<SeeMoreLinkProps, 'to'>
> = ({ total, openInNew }) => {
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();
    const trackActionCallback = useCallback(
        async () =>
            await trackAction(
                FeatureTracked.OpenPersonAllResults,
                actionTrackedInfo
            ),
        [actionTrackedInfo, trackAction]
    );
    return (
        <SeeMoreLink
            to="person"
            total={total}
            openInNew={openInNew}
            seeMoreLinkOnClick={trackActionCallback}
        />
    );
};

export const SeeMoreLinkCompany: React.FunctionComponent<
    Omit<SeeMoreLinkProps, 'to'>
> = ({ total, openInNew }) => {
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();
    const trackActionCallback = useCallback(
        async () =>
            await trackAction(
                FeatureTracked.OpenCompanyAllResults,
                actionTrackedInfo
            ),
        [actionTrackedInfo, trackAction]
    );
    return (
        <SeeMoreLink
            to="company"
            total={total}
            openInNew={openInNew}
            seeMoreLinkOnClick={trackActionCallback}
        />
    );
};
