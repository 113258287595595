import { FC } from 'react';
import { NotificationCountByProducerResponse } from 'services/ApiService/Notification/NotificationApiClient';
import { useNotificationListFilter } from 'components/hooks/Notification/useNotificationListFilter';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ProducerItem } from './ProducerItem';

type ProducerCountItemsProps = {
    countByProducers: NotificationCountByProducerResponse[],
    showTopSeparator?: boolean,
    allCount?: number,
};

export const ProducerCountItems: FC<ProducerCountItemsProps> = ({ countByProducers, showTopSeparator }) => {
    return <>
        {countByProducers.map((item, index) => <ProducerCountItem countByProducer={item} showTopSeparator={showTopSeparator} index={index} key={item.producer?.code} />)}
    </>;
};

type ProducerCountItemProps = {
    countByProducer: NotificationCountByProducerResponse,
    showTopSeparator?: boolean,
    index?: number,
};

export const ProducerCountItem: FC<ProducerCountItemProps> = ({ countByProducer, showTopSeparator, index }) => {
    const { producerCodeActive, readStatusActive, getFilterNavigatePath, isAllProducer } = useNotificationListFilter();
    const isAllCountLabel = isAllProducer(countByProducer.producer?.code ?? '');

    return (
        <li
            className={classNames({
                'border-top border-opacity-40': index === 0 && showTopSeparator,
                'active': countByProducer.producer?.code?.toLocaleLowerCase() === producerCodeActive.toLocaleLowerCase(),
            })}
        >
            <Link className="list-group-item list-group-item-action d-flex justify-content-between align-items-center text-decoration-none"
                to={getFilterNavigatePath(countByProducer.producer?.code, readStatusActive)}>
                {isAllCountLabel
                    ? <span className="fw-medium">{countByProducer.producer?.name}</span>
                    : <ProducerItem producer={countByProducer.producer} />
                }
                {countByProducer.unreadCount > 0 && <div className="badge badge-flat-info rounded-pill">{countByProducer.unreadCount}</div>}
            </Link>
        </li>
    );
};