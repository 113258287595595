import { useSelector } from 'react-redux';
import { NotificationCountByProducerResponse } from 'services/ApiService/Notification/NotificationApiClient';
import { ApiRequest, AppState } from 'store/store';

const countByProducers = (app: AppState) => {
    return app.notification.countByProducers || {};
};

type UseNotificationCountByProducerResponse = {
    countByProducers: Partial<ApiRequest<NotificationCountByProducerResponse[]>>;
    getUnreadCount: (producerCode: string) => number;
    getProducerName: (producerCode: string) => string;
    allUnreadTotalCount: number;
};

export const useNotificationCountByProducer = (): UseNotificationCountByProducerResponse => {
    const { isFetching, didInvalidate, data } = useSelector(countByProducers);
    const getCount = (producerCode: string): number => {
        if (!producerCode) {
            return 0;
        }
        return computeCount(producerCode);
    };

    const computeCount = (producerCode: Nullable<string>): number => {
        return data?.filter(d => !producerCode || d.producer?.code?.toLocaleLowerCase() === producerCode.toLocaleLowerCase())
            ?.reduce((cum, val) => cum + val.unreadCount, 0) ?? 0;
    };

    const getProducerName = (producerCode: Nullable<string>): string => {
        return data?.find(d => d.producer?.code?.toLocaleLowerCase() === producerCode?.toLocaleLowerCase())?.producer?.name || '';
    };

    return {
        countByProducers: { isFetching, data, didInvalidate },
        getUnreadCount: getCount,
        getProducerName,
        allUnreadTotalCount: computeCount(null),
    };
};