import classNames from 'classnames';
import { GetNotificationResponse } from 'services/ApiService/Notification/NotificationApiClient';
import { ProducerItem } from './Producer/ProducerItem';
import { UnreadIcon, DurationElement, SummaryElement, ActionToDoIcon } from './NotificationItem';

export const NotificationItemMobile = ({ notif }: { notif: GetNotificationResponse; }) => (
    <a href={notif.url} target="_blank" rel="noopener noreferrer"
        className={classNames('border-bottom border-opacity-40 list-group-item list-group-item-action p-0 d-block d-sm-none', {
            'bg-lvl2': notif.isRead,
            'pe-none': notif.isExpired,
        })}>
        <div className="d-flex align-items-sm-center px-3 notif-custom-space-t-12px notif-custom-space-b-12px"
            style={{ minHeight: '64px' }}>
            <div>
                <UnreadIcon isRead={notif.isRead} isExpired={notif.isExpired} />
            </div>
            <div className="d-flex flex-column flex-grow-1">
                <div className="d-flex justify-content-between ms-2">
                    <ProducerItem producer={notif.producer} withSmallFontSize isForExpiredNotification={notif.isExpired} />
                    <div className="ms-2">
                        <DurationElement createdDate={notif.createdDate} />
                    </div>
                </div>
                <div className="d-flex justify-content-between ms-2 mt-2">
                    <SummaryElement category={notif.category} payload={notif.payload} isExpired={notif.isExpired} />
                    <div className="ms-2">
                        <ActionToDoIcon kind={notif.kind} isExpired={notif.isExpired} />
                    </div>
                </div>
            </div>
        </div>
    </a>
);
