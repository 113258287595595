import { IServiceBoardClient } from 'core/externalClient/IServiceBoardClient';
import { Dispatch } from 'react';
import { ActionType } from 'store/actions/ActionType';
import {
    createGenerateUserDisclaimerFetchRequestAction,
    createGenerateUserDisclaimerFetchSuccessAction,
    createGenerateUserDisclaimerFetchFailureAction,
    createLastValidatedDisclaimerFetchRequestAction,
    createLastValidatedDisclaimerFetchSuccessAction,
    createLastValidatedDisclaimerFetchFailureAction,
} from 'store/actions/disclaimerAction';
import { ThunkResult } from 'store/store';
import { ITechnicalLogger } from '../common/logging/logger';

export const generateDisclaimer = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { serviceBoardClient, logger }
    ) => {
        dispatch(createGenerateUserDisclaimerFetchRequestAction());
        try {
            const disclaimer = await serviceBoardClient.generateDisclaimer();
            dispatch(createGenerateUserDisclaimerFetchSuccessAction(disclaimer));
            return disclaimer;
        } catch (error: any) {
            logger.error(error.message, error);
            await tryIgnoreDisclaimer(serviceBoardClient, logger);
            dispatch(createGenerateUserDisclaimerFetchFailureAction());
            return null;
        }
    };
};

export const getLastValidatedDisclaimer = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { serviceBoardClient, logger }
    ) => {
        dispatch(createLastValidatedDisclaimerFetchRequestAction());
        try {
            const disclaimer = await serviceBoardClient.getLastValidatedDisclaimer();
            dispatch(createLastValidatedDisclaimerFetchSuccessAction(disclaimer));
            return disclaimer;
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createLastValidatedDisclaimerFetchFailureAction());
            return null;
        }
    };
};

export const validateDisclaimer = (
    application: string
): ThunkResult => {
    return async (
        _dispatch: Dispatch<ActionType>,
        _state,
        { serviceBoardClient, logger }
    ) => {
        try {
            await serviceBoardClient.validateDisclaimer(application);
        } catch (error: any) {
            logger.error(error.message, error);
            await tryIgnoreDisclaimer(serviceBoardClient, logger);
        }
    };
};

const tryIgnoreDisclaimer = async (serviceBoardClient: IServiceBoardClient, logger: ITechnicalLogger): Promise<void> => {
    try {
        await serviceBoardClient.ignoreDisclaimer();
    } catch (error: any) {
        logger.error(error.message, error);
    }
};
