import { useLearnMoreCardTranslation } from 'components/hooks/translation/useLearnMoreCardTranslation';
import { LearnMoreItem } from 'models/learnMoreItem';
import { useTranslation } from 'react-i18next';
import { LearnMoreLink } from './LearnMoreLink';

export const LearnMoreCard = ({ item }: { item: LearnMoreItem }) => {
    const { translate } = useLearnMoreCardTranslation();
    const { t: translation } = useTranslation('learn-more');

    return (
        <LearnMoreLink
            item={item}
            style={{ minHeight: '300px' }}>
            <div
                className="card-body p-3"
                style={{
                    backgroundImage: `linear-gradient(0deg, rgba(81,87,87,.1)60%, rgba(81,87,87,.5)), url('${item.image_url}')`,
                    backgroundSize: 'cover',
                }}
            >
                <div className="badge badge-primary-alt badge-lg badge-prepend-square">
                    {translation(`learn-more:tag-${item.tag ?? 'story'}`)}
                </div>
            </div>

            <div className="card-footer p-3" style={{ minHeight: '80px' }}>
                <h5 className="fw-semibold mb-0 text-truncate-2">
                    {translate(item.title, item.title_fr)}
                </h5>
            </div>
        </LearnMoreLink>
    );
};