import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchItemResults } from 'components/hooks/search/useSearchItemResults';
import { QuicksearchSgmResults } from './QuicksearchSgmResults';
import { QuicksearchSgResults } from './QuicksearchSgResults';
import { QuicksearchCompanyResults } from './QuicksearchCompanyResults';
import { QuicksearchPersonResults } from './QuicksearchPersonResults';

import './QuicksearchResults.scss';

interface IQuicksearchResultsProps {
    openInNew: boolean;
}

export const QuicksearchResults: FC<IQuicksearchResultsProps> = ({ openInNew }) => {
    const { t: translate } = useTranslation('service-list');
    const { sgm, sg, person, company } = useSearchItemResults();

    return (
        <div
            className="position-absolute overflow-auto w-100 p-3 bg-lvl1 border shadow-max"
            style={{ maxHeight: '80vh' }}
        >
            <h3 className="visually-hidden">
                {translate('service-list:quicksearchResults')}
            </h3>
            <div className="mb-5">
                <QuicksearchSgmResults
                    sgmSearchResultItems={sgm.data}
                    isLoading={sgm.isFetching}
                    openInNew={openInNew}
                />
            </div>
            <QuicksearchSgResults
                sgSearchResultItems={sg.data}
                isLoading={sg.isFetching}
                openInNew={openInNew}
                forceEmptyMessage={sgm.data.items.length === 0}
            />
            <QuicksearchPersonResults
                personSearchResultItems={person.data}
                isLoading={person.isFetching}
                openInNew={openInNew}
            />
            <QuicksearchCompanyResults
                companySearchResultItems={company.data}
                isLoading={company.isFetching}
                openInNew={openInNew}
            />
        </div>
    );
};
