import { Loading } from 'components/common/loading/Loading';
import { useEssentialServices } from 'components/hooks/services/useEssentialServices';
import { getEssentialServices } from 'core/services/essentialServices/essentialServices';
import { getAccessServices } from 'core/services/serviceBoardService/serviceBoardService';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { EssentialsLayout } from './layout/essentials/EssentialsLayout';

const IsLoading = (state: AppState) => {
    return state.essentialService.isLoading;
};

const EssentialTitle = () => {
    const { essentialServices } = useEssentialServices();
    const { t: translate } = useTranslation('service-list');

    if (!essentialServices || essentialServices.length === 0) {
        return null;
    }

    return (
        <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3">
            <div className="mb-2 mt-3">
                <div className="mb-2 mb-lg-3">
                    <h1 className="h3 fw-bold mb-0 ps-0">
                        {translate('service-list:myEssentialsTitle')}
                    </h1>
                </div>
            </div>
        </div>
    );
};

export const MyServicesB2b2cPage = () => {
    const isLoading = useSelector(IsLoading);
    const dispatch = useDispatch();
    const {
        i18n: { language },
    } = useTranslation();

    useEffect(() => {
        dispatch<any>(getAccessServices(language));
        dispatch<any>(getEssentialServices(language || ''));
    }, [dispatch, language]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <EssentialTitle />
            <EssentialsLayout />
        </>
    );
};
