import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clipPaginateList } from 'components/pages/search/clip-list';
import { NoNonSgmServices } from '../../layout/NoServices';
import { FormLink } from './FormLink';
import { SeeMoreLinkSgAll } from 'components/pages/search/SeeMoreLink';
import { ServiceSearchItemCompactList } from './ServiceSearchItemCompactList';
import { EmptyLinePlaceholder } from 'components/common/EmptyLinePlaceholder';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { GetSearchResponse, SearchResultType } from 'services/ApiService/SgmSearch/SgmSearchApiClient';

import './QuicksearchResults.scss';

const MAX_NON_SGM_SERVICES = 3;

type QuicksearchSgResultsProps = {
    sgSearchResultItems: NonNullable<Required<GetSearchResponse['sg']>>;
    isLoading: boolean;
    openInNew: boolean;
    forceEmptyMessage: boolean;
};

export const QuicksearchSgResults: FC<QuicksearchSgResultsProps> = ({ sgSearchResultItems, isLoading, openInNew, forceEmptyMessage }) => {
    const { t: translate } = useTranslation('service-list');
    const { clipped, total, hasMore } = clipPaginateList(sgSearchResultItems, MAX_NON_SGM_SERVICES);
    const { isSgGroupCurrentUser } = useApplicationContext();

    if (!isSgGroupCurrentUser) {
        return null;
    }

    if (!isLoading && clipped.length === 0) {
        return (
            <>
                {forceEmptyMessage ? <NoNonSgmServices /> : null}
                <FormLink />
            </>
        );
    }

    return (
        <div>
            <h4 className="quicksearch-title text-secondary">
                {translate('service-list:nonSgmServices')}
            </h4>
            {isLoading ? (
                <EmptyLinePlaceholder count={MAX_NON_SGM_SERVICES} />
            ) : (
                <>
                    <ServiceSearchItemCompactList
                        serviceSearchItemLineInfos={clipped.map((sgItem) => {
                            return {
                                referenceId: sgItem.referenceId,
                                title: sgItem.title,
                                url: sgItem.url,
                                category: sgItem.category,
                                initials: sgItem.initials,
                                isInternal: undefined,
                                searchItemSource: sgItem.sourceInfos?.at(0)?.source,
                                searchType: SearchResultType.SgItem,
                                description: sgItem.description,
                            };
                        })}
                    />
                    {hasMore && (
                        <SeeMoreLinkSgAll total={total} openInNew={openInNew} />
                    )}
                    <FormLink />
                </>
            )}
        </div>
    );
};
