import { Suspense, useEffect, useState, FC } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import { HelpCenter } from './layout/helpCenter/HelpCenter';
import { useSelector, shallowEqual } from 'react-redux';
import { AppState } from 'store/store';
import { LanguageProvider } from './hooks/LanguageProvider';
import { TopicTracked, FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { IntroductionTour } from 'core/services/introductionTour/IntroductionTour';
import { AuthenticatedUser } from 'models/authenticatedUser';
import { LoadingPage } from './common/loading/LoadingPage';
import { DisclaimerLayout } from './pages/disclaimerPage/DisclaimerLayout';
import { ServiceBoardLayout } from './pages/serviceBoard/ServiceBoardLayout';
import { MyAccountPage } from './pages/myAccountPage/MyAccountPage';
import { NotificationClickTrackingPage } from './pages/notificationPage/Tracking/NotificationClickTrackingPage';
import { MyNotificationsPage } from './pages/notificationPage/NotificationList/MyNotificationPage';
import { NOTIFICATION_LIST_PATH } from 'models/constants';

import styles from '../app.module.scss';

const getContext = (app: AppState): { authenticatedUser: Nullable<AuthenticatedUser>, isLoading: boolean } => ({
    authenticatedUser: app.authentification.user,
    isLoading: app.applicationContext.isLoading,
});

const App: FC = () => {
    const [visitIsTracked, setVisitIsTracked] = useState<boolean>(false);
    const [showServiceLoader, setShowServiceLoader] = useState<boolean>(true);
    const [visitorIsTracked, setVisitorIsTracked] = useState<boolean>(false);
    const { trackAction } = useServiceTracking();
    const { authenticatedUser, isLoading } = useSelector(getContext, shallowEqual);

    useEffect(() => {
        let unmounted = false;
        const trackActionVisit = async () => {
            await trackAction(FeatureTracked.Visit, {
                topic: TopicTracked.Visit,
            });
        };

        // Track Visit
        if (!visitIsTracked && !unmounted) {
            setVisitIsTracked(true);
            trackActionVisit();
        }

        return () => {
            unmounted = true;
        };
    }, [trackAction, visitIsTracked]);

    useEffect(() => {
        let unmounted = false;
        const trackActionVisitor = async () => {
            await trackAction(FeatureTracked.Visitor, {
                topic: TopicTracked.Visit,
            });
        };

        // Track Visitor
        if (!visitorIsTracked && !unmounted && authenticatedUser) {
            setVisitorIsTracked(true);
            trackActionVisitor();
        }

        return () => {
            unmounted = true;
        };
    }, [trackAction, visitorIsTracked, authenticatedUser]);

    useEffect(() => {
        setTimeout(() => setShowServiceLoader(false), 1000);
    }, [showServiceLoader]);


    return <>
        <Suspense fallback={<LoadingPage />}>
            {showServiceLoader && <LoadingPage />}
            {!showServiceLoader && !isLoading && <>
                <LanguageProvider />
                <div className={`d-flex flex-column ${styles.main}`}>
                    <Switch>
                        <Route path="/disclaimer/*" element={<DisclaimerLayout />} />
                        <Route path="/myaccount/*" element={<MyAccountPage />} />
                        <Route path="/profilecenter/*" element={<Navigate replace={true} to="/myaccount" />} />
                        <Route path="/notification/:id/tracking/click" element={<NotificationClickTrackingPage />} />
                        <Route path={`${NOTIFICATION_LIST_PATH}/*`} element={<MyNotificationsPage />} />
                        <Route path="*" element={<ServiceBoardLayout />} />
                    </Switch>
                </div>
                <HelpCenter />
                <IntroductionTour />
            </>}
        </Suspense>
    </>;
};

export default App;
