import { FC } from 'react';
import { useHighlightLayout } from 'components/hooks/highlightLayout/useHighlightLayout';
import { HighlightLayout } from '../components/highlightLayout/HighlightLayout';

type CategoryFirstHighlightLayoutProps = {
    activeCategory: {
        Key?: string | null;
        Name?: string | null;
    };
}

export const CategoryFirstHighlightLayout: FC<CategoryFirstHighlightLayoutProps> = ({ activeCategory }) => {
    const { getLayoutsByCategoryKey } = useHighlightLayout();
    const highlightLayouts = getLayoutsByCategoryKey(activeCategory?.Key || '', 'Browse');
    const firstHighlightLayout = highlightLayouts.length > 0 ? highlightLayouts[0] : null;

    return <>
        {firstHighlightLayout && (
            <div className="mb-3 mb-md-4 mb-lg-5">
                <HighlightLayout layout={firstHighlightLayout} />
            </div>
        )}
    </>;
};