import { FC } from 'react';
import classNames from 'classnames';
import { redirectExternalOpenNewUrl } from 'core/services/redirectService';
import { MyHighlightImageBannerModel } from 'models/highlightLayoutModels';
import classnames from 'classnames';
import { getHighlightItemColCss } from './HighlightCard';
import { handleA11y } from 'core/utils/a11y';
import { FeatureTracked, TopicTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';

import './HighlightCard.scss';

type HighlightImageBannerCardProps = {
    imageBanner: Nullable<MyHighlightImageBannerModel>,
    columnSizing: Nullable<number>
}

export const HighlightImageBannerCard: FC<HighlightImageBannerCardProps> = ({ imageBanner, columnSizing }) => {
    const { trackAction } = useServiceTracking();

    if (!imageBanner) {
        return <></>;
    }

    const handleShowImageBannerDetail = () => {
        if (imageBanner.linkUrl) {
            redirectExternalOpenNewUrl(imageBanner.linkUrl);
            trackAction(FeatureTracked.OpenImageBanner, { topic: TopicTracked.CatalogForYou, parameters: { linkUrl: imageBanner.linkUrl } });
        }
    };

    const getTitleHeadCss = (columnSizing: Nullable<number>): string => {
        switch (columnSizing) {
            case 1:
                return 'h3';
            case 2:
                return 'h2';
            case 3:
                return 'h1';
            default:
                return '';
        }
    };

    return (
        <div className={classnames('highlight-card', getHighlightItemColCss(columnSizing))}>
            <div
                role="button"
                tabIndex={0}
                onClick={handleA11y(handleShowImageBannerDetail)}
                onKeyUp={handleA11y(handleShowImageBannerDetail)}
                className={classNames('card card-raising bg-light bg-opacity-20 h-100')}
                style={{
                    backgroundImage: `linear-gradient(0deg, rgba(81,87,87,0)50%, rgba(81,87,87,.9)), url('${imageBanner.backgroundImageUrl}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                }}
            >
                <div className="card-body pe-4 pb-4 d-flex">
                    <div className="image-banner-card-body">
                        <div className={classNames('card-title', getTitleHeadCss(columnSizing))}>{imageBanner.title}</div>
                        <p className="card-text">{imageBanner.subTitle}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};