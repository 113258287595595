import { FC, Fragment } from 'react';
import { useCatalogServices } from 'components/hooks/services/useBoardServices';
import { MyHighlightNonSgmServiceModel } from 'models/highlightLayoutModels';
import { BookmarkableServiceCard } from 'components/common/bookmarkableItem/BookmarkableServiceCard';
import { BookmarkOrigin } from 'models/bookmark';
import { TopicTracked } from 'models/tracking';

type HighlightNonServiceCardProps = {
    highlightNonSgmService: Nullable<MyHighlightNonSgmServiceModel>
}

export const HighlightNonSgmServiceCard: FC<HighlightNonServiceCardProps> = ({ highlightNonSgmService }) => {
    const { getNonSgmServiceById } = useCatalogServices();
    if (!highlightNonSgmService || !highlightNonSgmService.ids?.length) {
        return <></>;
    }

    const nonSgmServices = highlightNonSgmService.ids.map(x => getNonSgmServiceById(x));

    return <div className="row row-cols-4 row-cols-sm-5 row-cols-md-6 row-cols-lg-8 row-cols-xl-10 g-0 px-2">
        {nonSgmServices.map((x, index) =>
            <Fragment key={index}>
                {x && <BookmarkableServiceCard
                    bookmarkableItemId={x.Id || ''}
                    showAsSgmItem={false}
                    bookmarkOrigin={BookmarkOrigin.NonSgmService}
                    initials={x.Initials}
                    title={x.Title}
                    itemPageUrl={x.Url}
                    topicTracked={TopicTracked.CatalogForYou}
                    bookmarkId={x.BookmarkId}
                />}
            </Fragment>)}
    </div>;
};