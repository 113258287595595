import {
    ServiceBoardServiceFetchSuccessActionType,
    ServiceBoardServiceFetchRequestActionType,
    ServiceBoardServiceFetchFailureActionType,
} from '../actions/serviceBoardServiceAction';
import { ActionType } from 'store/actions/ActionType';
import { IServiceBoardServiceState, ServiceBoardServiceModel } from 'models/serviceBoardService';

const services = (
    state: IServiceBoardServiceState,
    action: ActionType
): IServiceBoardServiceState => {
    state ??= {
        data: {},
        accessServices: [],
        accessPages: [],        
    };

    switch (action.type) {
        case ServiceBoardServiceFetchSuccessActionType:
            return mergeHasAccess({
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: {
                        loading: false,
                        services: action.payload.services || [],
                        nonSgmServices: action.payload.nonSgmServices || [],
                    },                    
                },                
            });

        case ServiceBoardServiceFetchRequestActionType:
            return mergeHasAccess({
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: {
                        loading: true,
                        services: [],
                        nonSgmServices: [],
                    },
                },                
            });

        case ServiceBoardServiceFetchFailureActionType:
            return mergeHasAccess({
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: {
                        loading: false,
                        services: [],
                        nonSgmServices: [],
                    },
                },                
            });

        default:
            return state;
    }
};

const mergeHasAccess = (state: IServiceBoardServiceState): IServiceBoardServiceState => {
    const allAccessibleServices = Object.keys(state.data)
        .reduce((acc, value) => [...acc, ...state.data[value].services.filter(x => x.HasAccess)], [] as ServiceBoardServiceModel[]);

    const accessibleServiceKeys = allAccessibleServices
        .map(x => x.ServiceKey as string)
        .filter((value, index, array) => value && array.indexOf(value) === index);

    const accessiblePages = allAccessibleServices
        .map(x => x.ServicePages || [])
        .reduce((acc, value) => [...acc, ...value.map(x => x.Id as string)], [] as string[])
        .filter((value, index, array) => value && array.indexOf(value) === index);

    return {
        ...state,
        accessServices: accessibleServiceKeys,
        accessPages: accessiblePages,
    };
};

export default services;
