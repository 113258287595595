import { map, capitalize } from 'lodash-es';
import { CustomProperty, LogEntry } from 'models/logEntry';
import { getConfiguration } from '../configuration/configurationLoader';
import { getSGConnect } from '../sgConnect';
import { ApiException } from 'services/ApiService/Http';

const transformParamsToCustomProperties = (parameters?: {
    [key: string]: unknown;
}): CustomProperty[] => {
    if (parameters) {
        return map(parameters, (p, k) => ({
            Name: k,
            Value: typeof p === 'string' ? p : JSON.stringify(p),
            Type: typeof p,
        }));
    }
    return [];
};
interface FunctionalLog {
    LogType: 'Feature',
    Feature: string;
    Message: string;
    CustomProperties?: CustomProperty[];
}

const functionalLogs: FunctionalLog[] = [];
export const logFunctional = async (
    feature: string,
    message: string,
    parameters?: { [key: string]: unknown }
) => {
    functionalLogs.push({
        LogType: 'Feature',
        Feature: feature,
        Message: message,
        CustomProperties: transformParamsToCustomProperties(
            parameters
        ),
    });

    await sendLogs(functionalLogs);
};

export interface ITechnicalLogger {
    info(
        message: string,
        parameters?: { [key: string]: unknown }
    ): Promise<void>;
    warn(
        message: string,
        parameters?: { [key: string]: unknown }
    ): Promise<void>;
    error(
        message: string,
        error?: ApiException | Error,
        parameters?: { [key: string]: unknown }
    ): Promise<void>;
}

export class TechnicalLogger implements ITechnicalLogger {
    async info(
        message: string,
        parameters?: { [key: string]: unknown }
    ): Promise<void> {
        await logTechnical('info', message, parameters);
    }

    async warn(
        message: string,
        parameters?: { [key: string]: unknown }
    ): Promise<void> {
        await logTechnical('warn', message, parameters);
    }

    async error(
        message: string,
        error?: ApiException | Error,
        parameters?: { [key: string]: unknown }
    ): Promise<void> {
        if (error instanceof ApiException) {
            switch (error.status) {
                case 401:
                    getSGConnect().requestAuthorization();
                    return;
            }
        }

        if ((error instanceof ApiException || error instanceof Error) && (error.name === 'AbortError' || error.message === 'Failed to fetch')) {
            return;
        }

        const isDocumentDefined = typeof document !== 'undefined';
        let type: 'error' | 'warn' = 'error';
        if (error) {
            parameters = parameters || {};
            parameters['pageUrl'] = isDocumentDefined ? document.location?.pathname : undefined;
            parameters['stackTrace'] = error.stack || '';

            if (error instanceof ApiException) {
                parameters['statusCode'] = error.status;
                parameters['responseContent'] = error.data;
                if (error.status && error.status >= 400 && error.status < 500) {
                    type = 'warn';
                }
            }

            if (!message) {
                message = error.message;
            }

        }
        await logTechnical(type, message, parameters);
    }
}

const technicalLogs: LogEntry[] = [];
const logTechnical = async (
    type: 'info' | 'warn' | 'error',
    message: string,
    parameters?: { [key: string]: unknown }
) => {
    technicalLogs.push({
        LogType: 'Technical',
        Level: capitalize(type) as Capitalize<'info' | 'warn' | 'error'>,
        Message: message,
        CustomProperties: transformParamsToCustomProperties(parameters),
    });

    await sendLogs(technicalLogs);
};

async function sendLogs(logs: FunctionalLog[] | LogEntry[]): Promise<boolean> {
    if (logs.length === 0) {
        return true;
    }

    const logsToSend = [...logs];
    logs.splice(0, logs.length);

    let retryCount = 0;
    const jsonBody = JSON.stringify({ Log: logsToSend });

    const headers: HeadersInit = [
        ['accept', 'application/json'],
        ['content-type', 'application/json'],
    ];

    const sgConnect = getSGConnect();
    const token = sgConnect && sgConnect.getAuthorizationHeader();
    if (token) {
        headers.push(['authorization', token]);
    }

    const { serviceBoardUrl } = getConfiguration();
    const url = `${serviceBoardUrl}/v2/clientmonitoring`;

    do {
        const response = await fetch(url, {
            headers,
            method: 'POST',
            body: jsonBody,
        });

        if (!response.ok) {
            retryCount += 1;
            // Retry values:
            // - first time: 5 seconds
            // - second time: 20 seconds
            // - third time: 45 seconds
            await waitTimeout(retryCount * retryCount * 5000);
            continue;
        }
        return response.ok;
    } while (retryCount < 3);

    console.error(
        `Cannot send errors to the server after 3 times - url: ${url}`
    );
    return false;
}

const waitTimeout = (time: number) =>
    new Promise((resolve) =>
        // Retry values:
        // - first time: 5 seconds
        // - second time: 20 seconds
        // - third time: 45 seconds
        setTimeout(resolve, time)
    );