import { sortBy } from 'lodash-es';
import { ServiceBoardServiceModel } from 'models/serviceBoardService';

export enum SortField {
    Title = 'title',
    Category = 'category'
}

export const getSortField = (name: Nullable<string>): SortField => {
    if (!name) {
        return SortField.Title;
    }

    const fieldName = name.toLowerCase();
    if (Object.values(SortField as object).includes(fieldName)) {
        return fieldName as SortField;
    }
    return SortField.Title;
};

const sortServicesByTitle = (
    services: ServiceBoardServiceModel[]
): ServiceBoardServiceModel[] => sortBy(services, x => x.Title?.toLocaleLowerCase());

const sortServicesByCategory = (
    services: ServiceBoardServiceModel[]
): ServiceBoardServiceModel[] =>
    sortBy(services, [x => x.Category?.Name, x => x.Subcategory?.Name, x => x.Title?.toLocaleLowerCase()]);

const SORT_FUNCTIONS = {
    title: sortServicesByTitle,
    category: sortServicesByCategory,    
};

export const sortServicesBy = (
    services: ServiceBoardServiceModel[],
    sortField: SortField
): ServiceBoardServiceModel[] => {
    return SORT_FUNCTIONS[sortField](services);
};
