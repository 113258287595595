import { logFunctional } from 'core/services/common/logging/logger';
import {
    TopicTracked,
    FeatureTracked,
    ServiceTracked,
    TopicWithActionTracked,
    ActionTrackedInfo,
} from 'models/tracking';
import { AuthenticatedUser } from 'models/authenticatedUser';
import { TRACKING_SEARCH_TERM_KEY } from 'models/constants';
import { BookmarkOrigin } from 'models/bookmark';

export const topicWithActionTracked: TopicWithActionTracked = {
    [TopicTracked.Essentials]: [
        FeatureTracked.OpenService,
        FeatureTracked.AddBookmark,
        FeatureTracked.ViewServiceDetails,
        FeatureTracked.OpenAllMyServices,
    ],
    [TopicTracked.AllMyServices]: [
        FeatureTracked.OpenAllMyServices,
        FeatureTracked.OpenService,
        FeatureTracked.AddBookmark,
        FeatureTracked.RemoveBookmark,
        FeatureTracked.ViewServiceDetails,
        FeatureTracked.OpenSGMarketsTab,
        FeatureTracked.OpenSGTab,
    ],
    [TopicTracked.Bookmarks]: [
        FeatureTracked.OpenService,
        FeatureTracked.RemoveBookmark,
        FeatureTracked.ViewServiceDetails,
    ],
    [TopicTracked.Learnmore]: [FeatureTracked.OpenStory],
    [TopicTracked.RecommendedServices]: [
        FeatureTracked.OpenService,
        FeatureTracked.AddBookmark,
        FeatureTracked.ViewServiceDetails,
        FeatureTracked.OpenRequestAccess,
    ],
    [TopicTracked.QuickSearch]: [
        FeatureTracked.OpenQuickSearch,
        FeatureTracked.OpenService,
        FeatureTracked.AddBookmark,
        FeatureTracked.ViewServiceDetails,
        FeatureTracked.OpenRequestAccess,
        FeatureTracked.OpenAdvancedSearch,
        FeatureTracked.OpenSGMResults,
        FeatureTracked.OpenSGAllResults,
        FeatureTracked.OpenForm,
        FeatureTracked.OpenCompanyAllResults,
        FeatureTracked.OpenPersonAllResults,
        FeatureTracked.OpenCompany,
        FeatureTracked.OpenPerson,
        FeatureTracked.OpenSuggestedCategory,
    ],
    [TopicTracked.AdvancedSearch]: [
        FeatureTracked.OpenService,
        FeatureTracked.AddBookmark,
        FeatureTracked.ViewServiceDetails,
        FeatureTracked.OpenRequestAccess,
        FeatureTracked.OpenAdvancedSearch,
        FeatureTracked.OpenCompanyAllResults,
        FeatureTracked.OpenPersonAllResults,
        FeatureTracked.OpenSGMResults,
        FeatureTracked.OpenIcSearch,
        FeatureTracked.OpenSGAllResults,
        FeatureTracked.FilterSGMResults,
        FeatureTracked.FilterSGResults,
        FeatureTracked.FilterCompanyResults,
        FeatureTracked.FilterPersonResults,
        FeatureTracked.OpenForm,
        FeatureTracked.OpenCompany,
        FeatureTracked.OpenPerson,
        FeatureTracked.FilterSGResultsByCategory,
        FeatureTracked.FilterSGResultsBySubCategory,
    ],
    [TopicTracked.Help]: [FeatureTracked.OpenHelpCenter],
    [TopicTracked.ServiceDetail]: [
        FeatureTracked.OpenService,
        FeatureTracked.AddBookmark,
        FeatureTracked.Share,
        FeatureTracked.OpenRequestAccess,
    ],
    [TopicTracked.Visit]: [FeatureTracked.Visit, FeatureTracked.Visitor],
    [TopicTracked.ServiceboardMenu]: [FeatureTracked.OpenMyServices, FeatureTracked.OpenCatalog],
    [TopicTracked.CatalogForYou]: [
        FeatureTracked.OpenCatalogForYou,
        FeatureTracked.OpenService,
    ],
    [TopicTracked.CatalogBrowse]: [
        FeatureTracked.OpenCatalogBrowse,
        FeatureTracked.BrowseCategory,
        FeatureTracked.FilterBySubCategory,
        FeatureTracked.ViewAllMarketsSubCategory,
        FeatureTracked.ViewServiceDetails,
        FeatureTracked.ViewAsClient,
        FeatureTracked.AddBookmark,
        FeatureTracked.OpenRequestAccess,
        FeatureTracked.OpenService,
    ],
};

// TODO temporary ignore topicWithActionTracked in order to log all actions
// If business is ok with generated logs, remove topicWithActionTracked
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const shouldTrack = (topicTracked: TopicTracked, featureTracked: FeatureTracked) => {
    return topicTracked !== TopicTracked.None;
    // const topicFeatures = topicWithActionTracked[topicTracked];
    // return (
    //     topicTracked !== TopicTracked.None &&
    //     topicFeatures &&
    //     topicFeatures.some((f) => f === featureTracked)
    // );
};

export const isSearchTopicTracking = (topicTracked: TopicTracked, featureTracked: FeatureTracked) => {
    const isSearchTopic = topicTracked === TopicTracked.QuickSearch || topicTracked === TopicTracked.AdvancedSearch;
    return isSearchTopic && shouldTrack(topicTracked, featureTracked);
};

export const sendTrackService = async (
    featureTracked: FeatureTracked,
    serviceTracked: ServiceTracked,
    authenticatedUser: Nullable<AuthenticatedUser>,
    currentLanguage: Nullable<string>
) => {
    const service = serviceTracked.service;
    const topicTracked = serviceTracked.topic;
    const searchTerm = serviceTracked.searchTerm;
    const isSgmService = serviceTracked.origin === BookmarkOrigin.SgmService;

    if (service && shouldTrack(topicTracked, featureTracked)) {
        let logParams = {
            topic: topicTracked,
            'service-name': service.Title || '',
            'authenticated-user-email': authenticatedUser?.Email || '',
            'authenticated-user-icid': authenticatedUser?.IcId || '',
            language: currentLanguage || '',
            'origin': serviceTracked.origin,
            'is-internal': isSgmService
                ? service.IsInternal || false
                : true,
        };
        if (isSgmService) {
            logParams = {
                ...logParams,
                ...{
                    'category-key': service?.Category?.Key || '',
                    'category-label': service?.Category?.Name || '',
                    'sub-category-key': service?.Subcategory?.Key || '',
                    'sub-category-label': service?.Subcategory?.Name || '',
                },
            };
        }
        if (searchTerm) {
            logParams = {
                ...logParams,
                ...{ [TRACKING_SEARCH_TERM_KEY]: searchTerm },
            };
        }
        await logFunctional(FeatureTracked[featureTracked], '', logParams);
    }
};

export const sendTrackAction = async (
    featureTracked: FeatureTracked,
    actionTrackedInfo: ActionTrackedInfo,
    authenticatedUser: Nullable<AuthenticatedUser>,
    currentLanguage: Nullable<string>
) => {
    const topicTracked = actionTrackedInfo.topic;
    const actionTrackedInfoParams = actionTrackedInfo.parameters;
    if (shouldTrack(topicTracked, featureTracked)) {
        let logParams = {
            topic: topicTracked,
            'authenticated-user-email': authenticatedUser?.Email || '',
            'authenticated-user-icid': authenticatedUser?.IcId || '',
            language: currentLanguage || '',
        };
        if (actionTrackedInfoParams) {
            logParams = { ...logParams, ...actionTrackedInfoParams };
        }
        await logFunctional(FeatureTracked[featureTracked], '', logParams);
    }
};
