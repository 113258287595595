import { Dispatch } from 'react';
import { ThunkResult } from 'store/store';
import {
    createSetSearchTermAction,
    createSearchItemFetchFailureAction,
    createSearchItemFetchRequestAction,
    createSearchItemFetchSuccessAction,
} from './sgmSearchAction';
import { ActionType } from 'store/actions/ActionType';
import { SearchsApi } from 'services/ApiService';
import { debounce } from 'lodash-es';
import { Category, SearchResultType, Subcategory } from 'services/ApiService/SgmSearch/SgmSearchApiClient';
import { ITechnicalLogger } from 'core/services/common/logging/logger';
import { SEARCH_DEBOUNCE_IN_MS, SEARCH_GLOBAL_TIMEOUT_IN_MS } from 'config/constants';

const PAGE_SIZE = 31; // Max displayed elements on search page + 1. Check clipPaginateList function for more details

// Global awaiter
// If all promise are resolved before global timeout, all subpromises are resolved simultaneously
// If global timeout is thresholded, resolved promised are "freed"
export class GlobalPromise {
    private resolve = () => { /* Nothing to do */ };
    private subPromiseStates: boolean[] = [];
    private readonly global: Promise<void>;
    private readonly globalTimeout: number;

    constructor(globalTimeout = SEARCH_GLOBAL_TIMEOUT_IN_MS) {
        this.global = new Promise<void>((resolve) => this.resolve = resolve);
        this.globalTimeout = globalTimeout;
    }

    public addSubPromise(subPromiseFunc: (callback: () => Promise<void>) => Promise<unknown>) {
        const nextIndex = this.subPromiseStates.length;
        this.subPromiseStates[nextIndex] = false;
        const cb = () => {
            this.subPromiseStates[nextIndex] = true;
            if (this.subPromiseStates.filter(x => x === false).length === 0) {
                this.resolve();
            }
            return this.global;
        };

        subPromiseFunc(cb);
    }

    public start() {
        setTimeout(() => this.resolve(), this.globalTimeout);
    }
}

const debouncedSearchItems = debounce(async (dispatch: Dispatch<ActionType>, logger: ITechnicalLogger, requestKey: string, searchTerm: string, categories: string, subCategories: string, language?: string) => {
    const searchSpecificItems = async (searchResultTypes: SearchResultType[], callback: () => Promise<void>) => {
        try {
            const searchResult = await SearchsApi.search(searchTerm, language, 1, PAGE_SIZE, categories, subCategories, searchResultTypes);
            await callback();
            dispatch(createSearchItemFetchSuccessAction(requestKey, searchResult, searchResultTypes));
        } catch (error: any) {
            logger.error(error.message, error);
            await callback();
            dispatch(createSearchItemFetchFailureAction(requestKey, searchResultTypes));
        }
    };

    const globalPromise = new GlobalPromise();
    globalPromise.addSubPromise(cb => searchSpecificItems([SearchResultType.SgmItem, SearchResultType.SgItem], cb));
    globalPromise.addSubPromise(cb => searchSpecificItems([SearchResultType.People], cb));
    globalPromise.addSubPromise(cb => searchSpecificItems([SearchResultType.Company], cb));
    globalPromise.start();

}, SEARCH_DEBOUNCE_IN_MS);

export const searchItems = (searchTerm: string, categories: Category[], subCategories: Subcategory[], language?: string): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { logger }
    ) => {
        const categoriesKeys = categories.map(x => x.key).join(',');
        const subCategoriesKeys = subCategories.map(x => x.key).join(',');
        const requestKey = `${searchTerm}_${categoriesKeys}_${subCategoriesKeys}_${language}`;

        dispatch(createSearchItemFetchRequestAction(requestKey));
        debouncedSearchItems(dispatch, logger, requestKey, searchTerm, categoriesKeys, subCategoriesKeys, language);
    };
};

export const setSearchTerm = (searchTerm: string): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>
    ) => {
        dispatch(createSetSearchTermAction(searchTerm));
    };
};
