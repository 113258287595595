import { uniqueId } from 'lodash-es';
import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DefaultTooltip, TooltipButton } from 'components/common/tooltip/Tooltip';
import { useServiceDescriptionLink } from 'components/hooks/services/useServiceDescriptionLink';
import { FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useServiceTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { useBoardServiceForDetail } from 'components/hooks/services/useBoardServices';

export const TooltipInfoLink = ({
    serviceKey,
    isSgmService,
    tooltipText,
}: {
    serviceKey?: string | null;
    isSgmService: boolean;
    tooltipText?: string | null;
}) => {
    const { hasSgmServiceKey } = useBoardServiceForDetail();

    if (!isSgmService) {
        return <NonSgmServiceInfoLinkWithTooltip tooltipText={tooltipText} />;
    }
    if (!serviceKey) {
        return <SgmServiceNoDetailsInfoLinkWithTooltip />;
    }

    const service = hasSgmServiceKey(serviceKey);
    if (!service) {
        return <SgmServiceNoDetailsInfoLinkWithTooltip />;
    }
    return (
        <SgmServiceInfoLink serviceKey={serviceKey} tooltipText={tooltipText} />
    );
};

type InfoLinkProps = {
    serviceKey: Nullable<string>;
    className?: string;
    iconClassName?: string;
    linkId?: string;
    tooltipText?: Nullable<string>;
};

export const SgmServiceInfoLink: React.FunctionComponent<PropsWithChildren<InfoLinkProps>> = ({
    serviceKey,
    className,
    iconClassName,
    children,
    tooltipText,
}) => {
    const linkId = useMemo(() => uniqueId('infoLink'), []);
    const descriptionUrl = useServiceDescriptionLink(serviceKey);
    const { t: translate } = useTranslation('service-list');
    const { hasSgmServiceKey } = useBoardServiceForDetail();
    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();
    const hasInfoService = hasSgmServiceKey(serviceKey);

    if (!hasInfoService) {
        return null;
    }
    const message = tooltipText ? tooltipText : 'tooltip:viewDetailsSgmService';
    const label = translate(message);

    return (
        <DefaultTooltip text={label}>
            <Link
                id={linkId}
                to={descriptionUrl}
                onClick={async () =>
                    await trackService(
                        FeatureTracked.ViewServiceDetails,
                        serviceTracked
                    )
                }
                className={
                    className ||
                    'btn btn-flat-secondary btn-lg overflow-hidden all-services-button-width'
                }
            >
                {children || <span className="visually-hidden">{label}</span>}
                <i className={iconClassName || 'icon'}>info_outline</i>
            </Link>
        </DefaultTooltip>
    );
};

const DefaultDisabledInfoLink = ({ tooltipId }: { tooltipId: string }) => (
    <>
        <TooltipButton
            tooltipId={tooltipId}
            className="btn overflow-hidden all-services-button-width disabled"
            type="button"
            color="flat-secondary"
        >
            <i className={'icon'}>info_outline</i>
        </TooltipButton>
    </>
);

type NonSgmServiceInfoLinkWithTooltipProps = {
    tooltipText?: Nullable<string>
};

const NonSgmServiceInfoLinkWithTooltip: React.FunctionComponent<NonSgmServiceInfoLinkWithTooltipProps> = ({
    tooltipText,
}) => {
    const message = tooltipText ? tooltipText : 'tooltip:viewDetailsNonSgmService';
    return <DefaultDisabledInfoLink tooltipId={message} />;
};

const SgmServiceNoDetailsInfoLinkWithTooltip = () => (
    <DefaultDisabledInfoLink tooltipId="tooltip:viewDetailsSgmService" />
);