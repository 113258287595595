export const caseInsensitiveEquals = (
    target: string,
    toMatch: Nullable<string>
): boolean =>
    !!toMatch &&
    target.localeCompare(toMatch, undefined, { sensitivity: 'base' }) === 0;

export const matchServiceByKey = <T extends { ServiceKey?: Nullable<string> }>(
    targetKey: string
) => ({ ServiceKey }: T) => caseInsensitiveEquals(targetKey, ServiceKey);
