import * as React from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { ToastContentProps } from 'react-toastify';
import { displayDate } from 'services/date';

export type ToastMessageProps = Partial<ToastContentProps> & {
    messageOrTranslationKey?: string | null,
    titleOrTranslationKey?: string | null,
    messageComponent?: React.ReactNode,
    messageTranslationOptions?: { [key: string]: string },
    titleTranslationOptions?: { [key: string]: string },
};

export const ToastMessage: React.FC<ToastMessageProps> = ({ messageOrTranslationKey,
    titleOrTranslationKey,
    messageComponent,
    messageTranslationOptions,
    titleTranslationOptions,
    closeToast,
    toastProps,
}) => {
    const i18n = getI18n();
    const { t: translate } = useTranslation(Object.keys(i18n.store.data.en));

    let toastTitle = titleOrTranslationKey;
    if (!titleOrTranslationKey) {
        switch (toastProps?.type) {
            case 'error':
                toastTitle = 'Error';
                break;

            case 'warning':
                toastTitle = 'Warning';
                break;

            case 'success':
                toastTitle = 'Success';
                break;

            default:
                toastTitle = 'Information';
                break;
        }
    }
    
    const titleTranslated = translate(toastTitle ?? '', titleTranslationOptions);
    toastTitle = !titleTranslated ? toastTitle : titleTranslated;
    const messageTranslated = translate(messageOrTranslationKey ?? '', messageTranslationOptions);
    const message = !messageTranslated ? messageOrTranslationKey : messageTranslated;

    return (
        <>
            <div className="notification-header">
                <span className="fw-medium me-auto text-nowrap text-truncate">{toastTitle}</span>
                <span className="notification-timestamp text-nowrap">
                    {displayDate(new Date(), 'With diff from now')}
                </span>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeToast} />
            </div>
            <div
                className="notification-body text-primary"
                style={{ whiteSpace: 'pre-line' }} >
                {message}
                {messageComponent}
            </div>
        </>
    );
};