import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { DefaultTooltip } from 'components/common/tooltip/Tooltip';
import { uniqueId } from 'lodash-es';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { FeatureTracked } from 'models/tracking';
import { useActionTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { SearchResultCompleteItemSourceInfoDto, SearchResultType } from 'services/ApiService/SgmSearch/SgmSearchApiClient';
import { handleA11y } from 'core/utils/a11y';

import './SourceInfosSearchItemCompactList.scss';

const transformSourceSearchResultItemToParameters = (
    actionTrackedData: SourceSearchResultItem
) => ({
    'reference-id': actionTrackedData.referenceId,
    title: actionTrackedData.title,
    type: actionTrackedData.type,
    subtitle: actionTrackedData.subtitle,
    initials: actionTrackedData.initials,
});

const transformSourceInfosSearchResultItemToParameters = (
    trackedData: SearchResultCompleteItemSourceInfoDto
) => ({
    'service-name': trackedData.source,
    url: trackedData.url,
    'service-initials': trackedData.initials,
});

interface ILinkProps {
    service: SearchResultCompleteItemSourceInfoDto;
    feature: FeatureTracked;
    actionTrackedData: SourceSearchResultItem;
    render: 'icon' | 'popup';
}

const Link: FC<ILinkProps> = ({ render, service, feature, actionTrackedData }) => {
    const { t: translate } = useTranslation('service-list');
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();

    const trackCallback = () => trackAction(feature, {
        topic: actionTrackedInfo.topic,
        parameters: {
            ...transformSourceInfosSearchResultItemToParameters(service),
            ...transformSourceSearchResultItemToParameters(
                actionTrackedData
            ),
        },
    });

    if (render === 'icon') {
        const id = uniqueId('sourceLink');
        return (
            <div>
                <DefaultTooltip                    
                    text={translate('service-list:viewIn', {
                        name: service.source,
                    })}
                    placement="top-end"
                >
                    <a
                        href={service.url}
                        id={id}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="d-inline-flex d-flex justify-content-center align-items-center all-services-initials-icon-sm bg-white border text-primary ms-2 text-decoration-none"
                        onClick={(e) => {
                            trackCallback();
                            e.currentTarget.blur();
                        }}
                    >
                        {service.initials}
                    </a>
                </DefaultTooltip>
            </div>
        );
    }

    return (
        <div>
            <a
                href={service.url}
                onClick={trackCallback}
                rel="noopener noreferrer"
                target="_blank"
                className="list-group-item bg-white ps-0 py-2 text-decoration-none border-bottom text-primary"
            >
                <span className="me-2 d-inline-flex d-flex justify-content-center align-items-center all-services-initials-icon-sm bg-white border text-primary ms-2 text-decoration-none">
                    {service.initials}
                </span>
                {translate('service-list:viewIn', { name: service.source })}
            </a>
        </div>
    );
};

interface ISourceInfosSearchItemCompactListLineProps {
    dataLine: SourceSearchResultItem;
    useIcon?: string;
    feature: FeatureTracked;
}

const SourceInfosSearchItemCompactListLine: FC<ISourceInfosSearchItemCompactListLineProps> =
    ({ dataLine, useIcon, feature }) => {
        const id = `people-${dataLine.referenceId}`;

        const { trackAction } = useServiceTracking();
        const { actionTrackedInfo } = useActionTrackingContext();

        const handleClick = () => {
            if (dataLine?.sourceInfos?.length !== 1) {
                return;
            }

            trackAction(feature, {
                topic: actionTrackedInfo.topic,
                parameters: {
                    ...transformSourceInfosSearchResultItemToParameters(
                        dataLine.sourceInfos[0]
                    ),
                    ...transformSourceSearchResultItemToParameters(dataLine),
                },
            });
            window.open(dataLine.sourceInfos[0].url);
        };

        return (
            <li className="list-group-item p-0 me-1 me-sm-2 list-group-item-action border-bottom border-opacity-40">
                <div className="d-flex align-items-center justify-content-between text-primary me-1 me-sm-2">
                    <div
                        id={id}
                        onClick={handleA11y(handleClick)}
                        onKeyUp={handleA11y(handleClick)}
                        className="d-inline-flex flex-grow-1 align-items-center text-decoration-none p-1 p-sm-2"
                        role="button"
                        tabIndex={0}
                    >
                        <div className="flex-shrink-0 d-inline-flex d-flex justify-content-center align-items-center all-services-initials-icon-round border p-0">
                            {useIcon ? (
                                <span className="overflow-hidden icon display-4">
                                    {useIcon}
                                </span>
                            ) : (
                                <span className="overflow-hidden display-6 fw-medium">
                                    {dataLine.initials}
                                </span>
                            )}
                        </div>
                        <div className="d-inline-flex flex-column justify-content-center ms-3">
                            <span className="text-primary fw-semibold">
                                {dataLine.title}
                            </span>
                            <span className="text-secondary">
                                {dataLine.subtitle}
                            </span>
                        </div>
                    </div>
                    <div className="d-inline-flex float-right tooltip-secondary position-relative">
                        {dataLine.sourceInfos?.map((source, sourceId) => (
                            <Link
                                actionTrackedData={dataLine}
                                feature={feature}
                                service={source}
                                render="icon"
                                key={sourceId}
                            />
                        ))}
                    </div>
                    {dataLine.sourceInfos?.length ? (
                        <UncontrolledPopover
                            trigger="legacy"
                            target={id}
                            placement="bottom"
                        >
                            <PopoverBody className="p-0">
                                <ul className="list-group">
                                    {dataLine.sourceInfos.map(
                                        (source, sourceId) => (
                                            <Link
                                                actionTrackedData={dataLine}
                                                feature={feature}
                                                service={source}
                                                render="popup"
                                                key={sourceId}
                                            />
                                        )
                                    )}
                                </ul>
                            </PopoverBody>
                        </UncontrolledPopover>
                    ) : null}
                </div>
            </li>
        );
    };

interface ISourceInfosSearchItemCompactListProps {
    data: SourceSearchResultItem[];
    useIcon?: string;
    feature: FeatureTracked;
}
export const SourceInfosSearchItemCompactList: FC<ISourceInfosSearchItemCompactListProps> =
    ({ data, useIcon, feature }) => {
        return (
            <ul className="list-group border-top  w-100">
                {data.map((d) => (
                    <SourceInfosSearchItemCompactListLine
                        key={d.referenceId}
                        dataLine={d}
                        useIcon={useIcon}
                        feature={feature}
                    />
                ))}
            </ul>
        );
    };

interface SourceSearchResultItem {
    referenceId?: string
    title?: string
    url?: string
    initials?: string
    type?: SearchResultType
    subtitle?: string
    sourceInfos?: SearchResultCompleteItemSourceInfoDto[]
}