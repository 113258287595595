import { FC } from 'react';
import { InputForm } from 'components/common/form/InputForm';
import { ISignatureForm } from './EditSignature';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { TextAreaForm } from 'components/common/form/TextAreaForm';
import { CheckboxForm } from 'components/common/form/CheckboxForm';
import { ToogleForm } from 'components/common/form/ToogleForm';
import { useTranslation } from 'react-i18next';
import { getConfiguration } from 'core/services/common/configuration/configurationLoader';

type TForm = ISignatureForm;

type InfoPartSignatureProps = {
    register: UseFormRegister<TForm>;
    errors: FieldErrors<TForm>;
    isSubmitting: boolean;
}

export const InfoPartSignature: FC<InfoPartSignatureProps> = ({ register, errors, isSubmitting }) => {
    const { t: translate } = useTranslation('signature');

    const addressFormatOptions: { key: string, label: string }[] = [
        { key: 'Europe', label: translate('signature:addressFormat:europe') },
        { key: 'UK_US', label: translate('signature:addressFormat:ukus') },
    ];

    return <div className="row">
        <div className="col-12 col-lg-6">
            <InputForm<TForm>
                name="firstName"
                label={translate('signature:form:firstName')}
                placeholder={translate('signature:form:firstNamePlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="lastName"
                label={translate('signature:form:lastName')}
                placeholder={translate('signature:form:lastNamePlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="pronouns"
                label={translate('signature:form:pronouns')}
                placeholder={translate('signature:form:pronounsPlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="jobTitle"
                label={translate('signature:form:jobTitle')}
                placeholder={translate('signature:form:jobTitlePlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="jobTitlePart2"
                label={translate('signature:form:jobTitlePart2')}
                placeholder={translate('signature:form:jobTitlePart2Placeholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="department"
                label={translate('signature:form:department')}
                placeholder={translate('signature:form:departmentPlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <CheckboxForm<TForm>
                name="useDefaultDisclaimer"
                label={translate('signature:form:useDefaultDisclaimer')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <TextAreaForm<TForm>
                name="disclaimer"
                placeholder={translate('signature:form:disclaimerPlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <TextAreaForm<TForm>
                name="freeText"
                label={translate('signature:form:freeText')}
                placeholder={translate('signature:form:freeTextPlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
        </div>
        <div className="col-12 col-lg-6">
            <ToogleForm<TForm, string>
                name="addressFormat"
                label={translate('signature:form:addressFormat')}
                options={addressFormatOptions}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="street"
                label={translate('signature:form:street')}
                placeholder={translate('signature:form:streetPlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="zipCode"
                label={translate('signature:form:zipCode')}
                placeholder={translate('signature:form:zipCodePlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="city"
                label={translate('signature:form:city')}
                placeholder={translate('signature:form:cityPlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="directPhone"
                label={translate('signature:form:directPhone')}
                placeholder={translate('signature:form:directPhonePlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="mobilePhone"
                label={translate('signature:form:mobilePhone')}
                placeholder={translate('signature:form:mobilePhonePlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="website"
                label={translate('signature:form:website')}
                placeholder={translate('signature:form:websitePlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
            <InputForm<TForm>
                name="emailAddress"
                label={translate('signature:form:emailAddress')}
                placeholder={translate('signature:form:emailAddressPlaceholder')}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
        </div>
        <div className="col-12">
            <CheckboxForm<TForm>
                name="includeInOutlook"
                label={translate('signature:form:includeInOutlook')}
                helper={<OutlookHelper />}
                className="mb-2"
                register={register}
                errors={errors}
                disabled={isSubmitting}
            />
        </div>
    </div>;
};

const OutlookHelper: FC = () => {
    const { t: translate } = useTranslation('signature');
    const { sgLinkDocumentation } = getConfiguration();

    return <span className="ps-0 ps-sm-3 outlook-note">
        {translate('signature:form:includeInOutlookNotes')}
        &nbsp;
        <a className="text-link" href={sgLinkDocumentation} target="_blank" rel="noopener noreferrer">{translate('signature:form:includeInOutlookSgLink')}</a>
    </span>;
};