import { ApiRequest } from './store';

export const mergeRecords = <T extends string | number, V>(records: Record<T, ApiRequest<V>>, key: T, data: ApiRequest<V>) => {
    return {
        ...records,
        [key]: {
            ...records[key],
            ...data,
        },
    };
};

export const initialApiRequest = <T>(): ApiRequest<T> => ({
    isFetching: false,
    didInvalidate: true,
    hasError: false,
});