import {
    ExternalServiceOnlySetShowSuccessActionType,
} from '../actions/externalServiceAction';
import { ActionType } from 'store/actions/ActionType';

type ExternalServiceState = {
    showOnly: boolean;
};

const externalServiceReducer = (
    state: Nullable<ExternalServiceState> = null,
    action: ActionType
) => {
    if (action.type === ExternalServiceOnlySetShowSuccessActionType) {
        return { ...state, showOnly: action.payload };
    }
    return state;
};

export default externalServiceReducer;