import classNames from 'classnames';
import { useId } from 'react';
import { FieldValues, FieldPath, UseFormRegister, FieldErrors } from 'react-hook-form';

type CheckboxFormProps<T extends FieldValues> = {
    name: FieldPath<T>;
    label: string;
    helper?: JSX.Element;
    className?: string;
    helperClassName?: string;
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    disabled?: boolean;
}

export const CheckboxForm = <T extends FieldValues>({ name, label, helper, className, register, errors, disabled }: CheckboxFormProps<T>): JSX.Element => {
    const inputId = useId();
    const error = errors[name];

    const inputWrapperClassName = classNames('position-relative', {
        'is-invalid': error,
    });

    const inputClassName = classNames('form-check-input', {
        'is-invalid': error,
    });

    return <div className={classNames('form-check', 'flex-wrap', className)}>
        <div className={inputWrapperClassName}>
            <label
                htmlFor={inputId}
                className="form-check-label"
            >
                {label}
            </label>
            <input
                id={inputId}
                type="checkbox"
                {...register(name)}
                className={inputClassName}
                disabled={disabled}
            />
        </div>
        {helper && <span className="form-text">{helper}</span>}
        {error && <div className="invalid-feedback">
            {error.message as string}
        </div>}
    </div>;
};