import { Dispatch } from 'react';
import { ThunkResult } from 'store/store';
import {
    createApplicationContextFetchFailureAction,
    createApplicationContextFetchRequestAction,
    createApplicationContextFetchSuccessAction,
} from 'store/actions/applicationContextAction';

export const GetApplicationContext = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger }
    ) => {
        dispatch(createApplicationContextFetchRequestAction());
        try {
            const applicationContext = await serviceBoardClient.getApplicationContext();
            dispatch(createApplicationContextFetchSuccessAction(applicationContext));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createApplicationContextFetchFailureAction());
        }
    };
};
