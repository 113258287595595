import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AppState } from 'store/store';
import { useAllMyServices } from 'components/hooks/services/useAllMyServices';
import { EssentialsLayout } from './layout/essentials/EssentialsLayout';
import { EssentialsNavBar } from './layout/essentials/EssentialsNavBar';
import { AllServicesLayout } from './layout/allservices/AllServicesLayout';
import { RecommendationsLayout } from './layout/recommendations/RecommendationsLayout';
import { getAvailableLearnMore } from 'core/services/learnMore/availableLearnMore';
import { getRecommendations } from 'core/services/recommendations/recommendedServices';
import { LearnMoreLayout } from './layout/learnmore/LearnMoreLayout';
import { SingleServiceModal } from '../serviceDescription/SingleServiceModal';
import { BookmarksLayout } from './layout/bookmarks/BookmarksLayout';
import { LearnMoreModal } from '../learnMoreModal/LearnMoreModal';
import { getEssentialServices } from 'core/services/essentialServices/essentialServices';
import { getAccessServices } from 'core/services/serviceBoardService/serviceBoardService';
import { getCategories } from 'store/Categories/categoriesThunk';
import { getUserBookmarks } from 'core/services/bookmark/userBookmarkService';
import { Loading } from 'components/common/loading/Loading';

export const MyServicesPage = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: AppState) => state.essentialService.isLoading);
    const { t: translate, i18n: { language } } = useTranslation('service-list');

    useEffect(() => {
        dispatch<any>(getAccessServices(language));
        dispatch<any>(getCategories(language));
        dispatch<any>(getUserBookmarks());
        dispatch<any>(getEssentialServices(language || ''));
        dispatch<any>(getAvailableLearnMore());
        dispatch<any>(getRecommendations());
    }, [dispatch, language]);

    useEffect(() => {
        document.title = 'SG Markets - ' + translate('service-list:myServices');
    }, [language, translate]);

    if (isLoading) {
        return <div className="d-flex flex-grow-1">
            <Loading />
        </div>;
    }

    return (
        <>
            <Outlet />
            <SingleServiceModal />
            <LearnMoreModal />
        </>
    );
};

export const EssentialsOrAllServicesLayout = () => {
    const { showAllMyServicesTab } = useAllMyServices();
    return showAllMyServicesTab ? <AllServicesLayout /> : <EssentialsPage />;
};

export const EssentialsPage = () => (
    <>
        <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3">
            <EssentialsNavBar active="essentials" />
        </div>
        <EssentialsLayout />
        <BookmarksLayout />
        <LearnMoreLayout />
        <RecommendationsLayout />
    </>
);
