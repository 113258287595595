import { useLocation, useNavigate } from 'react-router-dom';
import { useCategories } from '../services/useCategories';
import { useSearchTerm } from './useSearchTerm';
import { Category, Subcategory } from 'services/ApiService/SgmSearch/SgmSearchApiClient';

const addQuery = (search: string, key: string, value: string): string => {
    const query = new URLSearchParams(search);
    const paramsValue = query.get(key)?.split(',') ?? [];

    if (paramsValue.includes(value)) {
        return search;
    }
    paramsValue.push(value);
    query.set(key, paramsValue.join(','));

    return query.toString();
};

const removeQuery = (search: string, key: string, value: string): string => {
    const query = new URLSearchParams(search);
    let paramsValue = query.get(key)?.split(',') ?? [];

    if (!paramsValue.includes(value)) {
        return search;
    }
    paramsValue = paramsValue.filter((x) => x !== value);

    if (paramsValue.length === 0) {
        query.delete(key);
    } else {
        query.set(key, paramsValue.join(','));
    }
    return query.toString();
};

export const unique = <T, K extends string | number | symbol>(
    array: readonly T[],
    toKey?: (item: T) => K
): T[] => {
    const valueMap = array.reduce((acc, item) => {
        const key = toKey
            ? toKey(item)
            : (item as any as string | number | symbol);
        if (acc[key]) return acc;
        return { ...acc, [key]: item };
    }, {} as Record<string | number | symbol, T>);
    return Object.values(valueMap);
};

const selectCategoriesByKeys = (
    categories: Category[],
    keys: string[]
) => {
    return unique(
        categories
            .filter((x) => keys.includes(x.key || ''))
            .map((e) => ({ key: e.key, label: e.label })),
        (k) => k.key || ''
    );
};

const selectSubcategoriesByKeys = (
    categories: Category[],
    keys: string[]
) => {
    const allSubcategories = categories.reduce<Subcategory[]>((result, value) => {
        if (value.subcategories) {
            return result.concat(value.subcategories);
        }
        return result;
    }, []);

    return unique(
        allSubcategories
            .filter((x) => keys.includes(x.key || ''))
            .map((e) => ({ key: e.key, label: e.label })),
        (k) => k.key || ''
    );
};

const categoriesSgmFilterKey = 'filters.sgm.categoryKeys';
const subcategoriesSgmFilterKey = 'filters.sgm.subcategoryKeys';

export const useSearchQuery = () => {
    const { search } = useLocation();
    const { getSearchRoute } = useSearchTerm();
    const categories = useCategories();
    const navigate = useNavigate();

    const params = new URLSearchParams(search);

    const selectedCategories: Category[] =
        selectCategoriesByKeys(
            categories,
            params.get(categoriesSgmFilterKey)?.split(',') || []
        );

    const selectedSubcategories = selectSubcategoriesByKeys(
        categories,
        params.get(subcategoriesSgmFilterKey)?.split(',') || []
    );

    const route = getSearchRoute('sgmarkets');

    return {
        toString: () => params.toString(),
        addCategory: (categoryKey: string) => {
            const newSearch = addQuery(
                search,
                categoriesSgmFilterKey,
                categoryKey
            );
            navigate({
                search: newSearch,
                pathname: route.pathname,
            });
        },
        removeCategory: (categoryKey: string) => {
            const subcategories =
                categories.find((e) => e.key === categoryKey)
                    ?.subcategories ?? [];
            let newSearch = removeQuery(
                search,
                categoriesSgmFilterKey,
                categoryKey
            );
            for (const sub of subcategories) {
                newSearch = removeQuery(
                    newSearch,
                    subcategoriesSgmFilterKey,
                    sub.key || ''
                );
            }
            navigate({
                search: newSearch,
                pathname: route.pathname,
            });
        },
        addSubcategory: (subcategoryKey: string, categoryKey?: string) => {
            let newSearch = addQuery(
                search,
                subcategoriesSgmFilterKey,
                subcategoryKey
            );
            if (categoryKey) {
                newSearch = addQuery(
                    newSearch,
                    categoriesSgmFilterKey,
                    categoryKey
                );
            }
            navigate({
                search: newSearch,
                pathname: route.pathname,
            });
        },
        removeSubcategory: (subcategoryKey: string) => {
            const newSearch = removeQuery(
                search,
                subcategoriesSgmFilterKey,
                subcategoryKey
            );
            navigate({
                search: newSearch,
                pathname: route.pathname,
            });
        },
        selectedCategories,
        selectedSubcategories,
    };
};
