import { FC, useEffect } from 'react';
import { useMatch } from 'react-router';
import NotFoundPage from 'components/pages/errors/NotFound';
import { LoadingPage } from 'components/common/loading/LoadingPage';
import { useDispatch, useSelector } from 'react-redux';
import { doMarkAsClicked } from 'store/Notification/notificationThunk';
import { AppState } from 'store/store';

const getNotificationMarkAsClicked = (app: AppState) => {
  return app.notification.markAsClicked || {};
};

const isValidUrl = (str?: string): boolean => {
  try {
    const url = new URL(str || '');
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
};

export const NotificationClickTrackingPage: FC = () => {
  const match = useMatch('/notification/:id/tracking/click');
  const dispatch = useDispatch();
  const { isFetching, neverFetched, hasError, data } = useSelector(getNotificationMarkAsClicked);

  const id = parseInt(match?.params.id ?? '', 10);

  useEffect(() => {
    dispatch<any>(doMarkAsClicked(id));
  }, [id]);

  if (!neverFetched && !isFetching && !hasError) {
    if (isValidUrl(data?.originalUrl)) {
      window.location.replace(data?.originalUrl || '');
    } else {
      window.location.replace('/');
    }
  }

  return (neverFetched || isFetching || !hasError) ? <LoadingPage /> : <NotFoundPage />;
};
