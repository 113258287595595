import { FC, useCallback } from 'react';
import { useCatalogServices } from 'components/hooks/services/useBoardServices';
import { ServiceCard } from 'components/common/service-card/ServiceCard';
import { ActionTrackingInfoProvider, useActionTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { CatalogBrowseSubCategoryFilter, allSubCategoryKey } from '../CatalogBrowseSubCategoryFilter';
import { CategoryFirstHighlightLayout } from '../CategoryFirstHighlightLayout';

import './CatalogBrowseCategory.scss';

interface ICatalogBrowseCategoryProps {
    categoryKey: string;
    subCategoryKey: Nullable<string>;
}

export const CatalogBrowseCategory: FC<ICatalogBrowseCategoryProps> = ({ categoryKey: category, subCategoryKey: subCategory }) => {
    const { visibleServices } = useCatalogServices();
    const { actionTrackedInfo } = useActionTrackingContext();

    const categoryServices = visibleServices.filter(x => x.Category?.Key === category);
    const categoryFound = categoryServices.find(x => x.Category?.Key === category)?.Category;
    const subCategoryFound = categoryServices.find(x => x.Subcategory?.Key === subCategory)?.Subcategory;
    const activeSubCategory = subCategoryFound?.Key ?? allSubCategoryKey;
    const subServices = categoryServices.filter(x => activeSubCategory === allSubCategoryKey || x.Subcategory?.Key === activeSubCategory) || [];

    const trackInfos = useCallback(() => {
        return {
            topic: actionTrackedInfo.topic,
            parameters: {
                'category-key': categoryFound?.Key || '',
                'category-label': categoryFound?.Name || '',
                'sub-category-key': subCategoryFound?.Key || '',
                'sub-category-label': subCategoryFound?.Name || '',
            },
        };
    }, [actionTrackedInfo.topic, categoryFound?.Key, categoryFound?.Name, subCategoryFound?.Key, subCategoryFound?.Name]);

    return <>
        {categoryFound && <>
            <CategoryFirstHighlightLayout activeCategory={categoryFound} />
            <CatalogBrowseSubCategoryFilter
                activeCategory={categoryFound}
                activeSubCategory={subCategoryFound}
                categoryServices={categoryServices}
                trackInfos={trackInfos}
            />
        </>}
        <div className="mb-3 mb-md-4 mb-lg-5 mt-3">
            <div className="row g-2 g-md-3 g-lg-3 py-3">
                {subServices.map((s) =>
                    <div className="col-6 col-md-4 col-lg-3" key={s.ServiceKey}>
                        <ActionTrackingInfoProvider actionTrackedInfo={trackInfos()} >
                            <ServiceCard service={s} />
                        </ActionTrackingInfoProvider>
                    </div>
                )}
            </div>
        </div>
    </>;
};