import { FC } from 'react';
import { DropdownDetails } from 'components/pages/myServicesPage/components/details/DetailsDropdown';
import { ignoreRedirect } from 'core/services/redirectService';
import { BookmarkOrigin } from 'models/bookmark';
import { TopicTracked, ServiceTracked, FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { ServiceTrackingInfoProvider } from 'components/common/tracking/serviceTrackingContext';
import classNames from 'classnames';

import './BookmarkableItem.scss';


export type BookmarkableServiceCardProps = {
    bookmarkableItemId: string,
    showAsSgmItem: boolean,
    bookmarkOrigin: Nullable<BookmarkOrigin>,
    initials: Nullable<string>,
    title: Nullable<string>,
    itemPageUrl: Nullable<string>,
    topicTracked: TopicTracked,
    bookmarkId: Nullable<number>,
}

export const BookmarkableServiceCard: FC<BookmarkableServiceCardProps> = ({ bookmarkableItemId, showAsSgmItem, bookmarkOrigin, initials, title, itemPageUrl, topicTracked, bookmarkId }) => {
    const { trackService } = useServiceTracking();

    const serviceTracked: ServiceTracked = {
        topic: topicTracked,
        service: {
            ServiceKey: bookmarkableItemId,
            Title: title,
            BookmarkId: bookmarkId,
        },
        origin: bookmarkOrigin,
    };
    
    return (
        <ServiceTrackingInfoProvider serviceTracked={serviceTracked}>
            <div className="col">
                <a
                    href={itemPageUrl || undefined}
                    onClick={async () => await trackService(FeatureTracked.OpenService, serviceTracked)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="card bookmarkable-card p-0 text-decoration-none"
                >
                    <div className="card card-filling-secondary h-100">
                        <div className="card-body d-flex align-items-center justify-content-center pt-2 pt-md-3 pb-2 px-0">
                            <div className={classNames('card card-raising bookmarkable-card-frame d-flex justify-content-center align-items-center p-0 border display-4', {
                                'bg-white': showAsSgmItem,
                                'bg-opacity-10 bg-secondary': !showAsSgmItem,
                            })} >
                                <span className={classNames('fw-medium', {
                                    'text-black': showAsSgmItem,
                                    'text-secondary': !showAsSgmItem,
                                })}>{initials || ''}</span>
                            </div>
                        </div>
                        <div className="card-footer text-center p-0">
                            <label className="col text-primary fw-medium bookmarkable-icon-label">
                                {title}
                            </label>
                            <div className="d-none d-lg-block bookmarkable-dropdown">
                                <DropdownDetails
                                    serviceKey={bookmarkableItemId}
                                    bookmarkOrigin={bookmarkOrigin}
                                    onToggle={ignoreRedirect} />
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </ServiceTrackingInfoProvider>
    );
};