import { FC } from 'react';
import { MyHighlightItemModel } from 'models/highlightLayoutModels';
import { HighlightServiceCard } from './HighlightServiceCard';
import { HighlightImageBannerCard } from './HighlightImageBannerCard';
import { HighlightNonSgmServiceCard } from './HighlightNonSgmServiceCard';
import { HighlightInsightPublicCard } from './HighlightInsightPublicCard';
import { HighlightRecommendedCard } from './HighlightRecommendedCard';

import './HighlightCard.scss';

type HighlightProps = {
    highlight: MyHighlightItemModel
};

export const HighlightCard: FC<HighlightProps> = ({ highlight }) => {
    if (!highlight) {
        return <></>;
    }

    switch (highlight.highlightType) {
        case 'Service':
            return <HighlightServiceCard highlightService={highlight.service} columnSizing={highlight.columnSizing} />;
        case 'ImageBanner':
            return <HighlightImageBannerCard imageBanner={highlight.imageBanner} columnSizing={highlight.columnSizing} />;
        case 'NonSgmService':
            return <HighlightNonSgmServiceCard highlightNonSgmService={highlight.nonSgmService} />;
        case 'InsightPublic':
            return <HighlightInsightPublicCard insightPublic={highlight.insightPublic} />;
        case 'Recommended':
            return <HighlightRecommendedCard highlightRecommended={highlight.recommended} />;
        default:
            return <></>;
    }
};

export const getHighlightItemColCss = (columnSizing: Nullable<number>): string => {
    switch (columnSizing) {
        case 1:
            return 'col-6 col-md-4 col-lg-3';
        case 2:
            return 'col-11 col-md-6 col-lg-6';
        case 3:
            return 'col-11 col-md-9 col-lg-9';
        default:
            return '';
    }
};