import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createBookmarkFetchFailureAction,
    createBookmarkFetchRequestAction,
    createBookmarkFetchSuccessAction,

    createBookmarkCreateFailureAction,
    createBookmarkCreateRequestAction,
    createBookmarkCreateSuccessAction,

    createBookmarkDeleteFailureAction,
    createBookmarkDeleteRequestAction,
    createBookmarkDeleteSuccessAction,
} from '../../../store/actions/bookmarkAction';
import { first } from 'lodash-es';
import { BookmarkOrigin } from 'models/bookmark';

export const getUserBookmarks = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger }
    ) => {
        dispatch(createBookmarkFetchRequestAction());
        try {
            const bookmarks = await serviceBoardClient.getUserBookmarks();
            dispatch(createBookmarkFetchSuccessAction(bookmarks?.Items ?? null));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createBookmarkFetchFailureAction());
        }
    };
};

export const createUserBookmark = (referenceId: Nullable<string>, origin: Nullable<BookmarkOrigin>): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger, toaster }
    ) => {
        dispatch(createBookmarkCreateRequestAction());
        try {
            const bookmarkCreated = await serviceBoardClient.createUserBookmarks(referenceId, origin);            
            toaster.toastUsingTranslationSuccess({
                messageTranslationKey: 'bookmark:addToBookmarkToaster',
                messageTranslationOptions: { title: bookmarkCreated?.Title ?? '' },
            });
            dispatch(createBookmarkCreateSuccessAction(bookmarkCreated));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createBookmarkCreateFailureAction());
        }
    };
};

export const deleteUserBookmark = (id: number): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        state,
        { serviceBoardClient, logger, toaster }
    ) => {
        dispatch(createBookmarkDeleteRequestAction());
        const title = first(state().bookmark?.items?.filter(e => e.Id === id))?.Title ?? '';
        try {
            await serviceBoardClient.deleteUserBookmarks(id);            
            toaster.toastUsingTranslationSuccess({
                messageTranslationKey: 'bookmark:removeBookmarkToaster',
                messageTranslationOptions: { title },
            });
            dispatch(createBookmarkDeleteSuccessAction(id));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createBookmarkDeleteFailureAction());
        }
    };
};