import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const CountryRestrictedPage: FC = () => {
    const { t: translate } = useTranslation('disclaimer');
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/');
    };

    return (
        <div className="d-flex justify-content-center bg-lvl2">
            <div className="d-flex flex-column col-lg-6 disclaimer-page-column">
                <div className="d-flex justify-content-center mt-5">
                    <div
                        style={{ width: '64px', height: '64px' }}
                        className="d-flex align-items-center justify-content-center bg-opacity-20 bg-secondary rounded-circle"
                    >
                        <i className="icon icon-lg">lock</i>
                    </div>
                </div>

                <h2 className="fw-bold text-center mt-4">
                    {translate('disclaimer:countryRestrictedTitle')}
                </h2>

                <p className="text-center mt-3 mb-5">
                    {translate('disclaimer:countryRestrictedPart1')}
                    <br />
                    {translate('disclaimer:countryRestrictedPart2')}
                </p>

                <div className="d-flex justify-content-center mt-4">
                    <div className="d-flex">
                        <button
                            className="btn btn-discreet-primary btn-lg me-2"
                            onClick={goBack}
                        >
                            {translate('disclaimer:goBack')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
