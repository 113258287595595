import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'components/hooks/navigation/useQuery';
import { getSortField, SortField } from './sort-service-list';
import classNames from 'classnames';
import { handleA11y } from 'core/utils/a11y';

const useSorting = () => {
    const navigate = useNavigate();
    const { getParam, getLink } = useQuery();
    const currentSort = getSortField(getParam('sort'));

    const setSort = (newSort: SortField) => {
        navigate(getLink({ sort: newSort }));
    };

    return { currentSort, setSort };
};

export const SortMenu = () => {
    const { t: translate } = useTranslation('service-list');
    const { currentSort, setSort } = useSorting();

    const handleSortChange = (sortField: SortField) => {
        setSort(sortField);
    };

    return (
        <div className="btn-group">
            <button className="btn btn-flat-primary btn-lg dropdown-toggle m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="d-none d-sm-block">
                    {translate('service-list:sortBy')}{' '}
                </div>
                <div className="d-block d-sm-none">
                    {translate('service-list:sort')}{' '}
                </div>
            </button>
            <ul className="dropdown-menu">
                <li
                    className={classNames('dropdown-item', { 'active': currentSort === SortField.Title })}
                    onClick={handleA11y(() => handleSortChange(SortField.Title))}
                    onKeyUp={handleA11y(() => handleSortChange(SortField.Title))}
                    role="option"
                    aria-selected={currentSort === SortField.Title}
                    tabIndex={0}
                >
                    {translate('service-list:sortOptionAlphabetical')}
                </li>
                <li
                    className={classNames('dropdown-item', { 'active': currentSort === SortField.Category })}
                    onClick={handleA11y(() => handleSortChange(SortField.Category))}
                    onKeyUp={handleA11y(() => handleSortChange(SortField.Category))}
                    role="option"
                    aria-selected={currentSort === SortField.Category}
                    tabIndex={0}
                >
                    {translate('service-list:sortOptionCategory')}
                </li>
            </ul>
        </div>
    );
};