import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { searchNotifications } from 'store/Notification/notificationThunk';
import { AppState } from 'store/store';
import {
    PAGE_SIZE,
    useNotificationListFilter,
} from 'components/hooks/Notification/useNotificationListFilter';
import { SkeletonWrapper } from 'components/common/SkeletonWrapper';
import Skeleton from 'react-loading-skeleton';
import { NotificationFilter } from './Filter/NotificationFilter';
import { NotificationItem } from './NotificationItem';
import { useNotificationTitle } from 'components/hooks/Notification/useNotificationTitle';
import { ReadStatus } from 'services/ApiService/Notification/NotificationApiClient';

export const Notifications: FC = () => {
    const { t: translate } = useTranslation('my-notification');
    const dispatch = useDispatch();
    const { producerCodeActive, readStatusActive, isAllProducer } = useNotificationListFilter();
    const { isFetching, neverFetched, didInvalidate, data } = useSelector((state: AppState) => state.notification.searchResult);
    const { title } = useNotificationTitle();

    const handleInfinityScroll = () => {
        const scrollApproxMargin = 70;
        if (window.innerHeight + Math.ceil(document.documentElement.scrollTop) < document.documentElement.offsetHeight - scrollApproxMargin
            || isFetching
            || data?.pageMeta?.isLastPage === true
        ) {
            return;
        }
        fetchNotifications(true);
    };

    const fetchNotifications = (fetchNextPage: boolean) => {
        let page = data?.pageMeta?.page ?? 1;
        if (fetchNextPage) {
            page++;
        }
        else {
            page = 1;
        }

        const producerCodes = isAllProducer(producerCodeActive) ? [] : [producerCodeActive];
        const includeExpired = readStatusActive === ReadStatus.Any ? true : false;
        dispatch<any>(searchNotifications({
            producerCodes: producerCodes,
            page: page,
            pageSize: PAGE_SIZE,
            readStatus: readStatusActive,
            includeExpired: includeExpired,
        }));
    };

    useEffect(() => {
        window.addEventListener('scroll', handleInfinityScroll);
        return () => window.removeEventListener('scroll', handleInfinityScroll);
    }, [isFetching, data]);

    useEffect(() => {
        fetchNotifications(false);
    }, [producerCodeActive, readStatusActive, didInvalidate]);

    if (neverFetched) {
        return <div className="d-flex flex-column col-12 col-sm-11 col-xl-8 ms-0 ms-sm-24px ms-xl-96px ms-xxl-128px flex-grow-1">
            <NotificationsSkeleton />
        </div>;
    }

    return (
        <div className="d-flex flex-column col-12 col-sm-11 col-xl-8 ms-0 ms-sm-24px ms-xl-96px ms-xxl-128px flex-grow-1">
            <div className="d-flex justify-content-sm-between align-sm-items-center mt-2 mt-sm-4 mb-0 mb-sm-3">
                <h2 className="d-none d-sm-block text-truncate align-self-center">
                    {title}
                </h2>
                <div className="d-flex flex-grow-1">
                    <NotificationFilter />
                </div>
            </div>
            <div className="border flex-grow-1 border border-opacity-40 border-bottom-0 list-group notifications-wrapper">
                {data?.notifications?.length
                    ? data?.notifications.map((notif, index) => (<NotificationItem notif={notif} key={`notif_${index}`} />))
                    : <h4 className="text-center mt-3">{translate('noNotification')}</h4>
                }
            </div>
        </div>
    );
};

const NotificationsSkeleton = () => {
    return (
        <SkeletonWrapper>
            <div className="d-flex justify-content-between my-2">
                <div>
                    <Skeleton height={50} width={115} />
                </div>
                <div>
                    <Skeleton height={50} width={200} />
                </div>
            </div>
            <Skeleton height={80} className="mb-1" count={20} />
        </SkeletonWrapper>
    );
};
