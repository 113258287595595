import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createEssentialServicesFetchFailureAction,
    createEssentialServicesFetchRequestAction,
    createEssentialServicesFetchSuccessAction,
} from '../../../store/actions/essentialServicesAction';

export const getEssentialServices = (language?: string): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        state,
        { serviceBoardClient, logger }
    ) => {
        const { essentialService: { isLoading, items } } = state();
        if (isLoading || items) {
            return;
        }

        dispatch(createEssentialServicesFetchRequestAction());
        try {
            const essentialServicesModel = await serviceBoardClient.getEssentialServices(language);
            dispatch(createEssentialServicesFetchSuccessAction(essentialServicesModel?.Items ?? null));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createEssentialServicesFetchFailureAction());
        }
    };
};
