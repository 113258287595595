import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAllMyServices } from 'components/hooks/services/useAllMyServices';
import { scrollUp } from 'core/utils/animations';
import { NavigationEssentialsNavbar } from 'models/navigation';
import { TopicTracked, FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import {
    ActionTrackingInfoProvider,
    useActionTrackingContext,
} from 'components/common/tracking/serviceTrackingContext';
import { Nav, NavItem } from 'reactstrap';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';

type EssentialsNavBarProps = {
    active: NavigationEssentialsNavbar;
};

export const EssentialsNavBar: React.FunctionComponent<EssentialsNavBarProps> = ({
    active,
}) => {
    const { sgmServices, showAllMyServicesTab } = useAllMyServices();
    const { t: translate } = useTranslation('service-list');
    const mediaBreakpoint = useMediaBreakpoint();

    if (sgmServices.length === 0 && !showAllMyServicesTab) {
        return null;
    }

    const navHeadClassName = {
        'h5': ['xs', 'sm'].includes(mediaBreakpoint),
        'h2': ['md', 'lg', 'xl', 'xxl'].includes(mediaBreakpoint),
    };

    return (
        <ActionTrackingInfoProvider
            actionTrackedInfo={{ topic: TopicTracked.Essentials }}
        >
            <div className="d-flex align-items-center justify-content-between py-md-3 py-1">
                <div id="navs" className={classNames('d-block', navHeadClassName)}>
                    <Nav>
                        <NavItem>
                            <EssentialsNavBarLink
                                className={classNames('nav-link fw-bold ps-0 me-md-4', {
                                    active: active === 'essentials',
                                })}
                                to="essentials"
                            >
                                {translate('service-list:myEssentialsTitle')}
                            </EssentialsNavBarLink>
                        </NavItem>
                        <NavItem>
                            {showAllMyServicesTab && (<EssentialsNavBarLink
                                className={classNames('nav-link fw-bold ps-0 me-md-4', {
                                    active: active === 'all-services',
                                })}
                                to="all-services"
                            >
                                {translate('service-list:allServices')}
                            </EssentialsNavBarLink>)}
                        </NavItem>
                    </Nav>
                </div>
            </div>
        </ActionTrackingInfoProvider>
    );
};

type EssentialsNavBarLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
    to: NavigationEssentialsNavbar;
};
export const EssentialsNavBarLink: React.FunctionComponent<EssentialsNavBarLinkProps> = ({
    to,
    children,
    ...linkProps
}) => {
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();

    const onLinkClicked = async () => {
        scrollUp();
        if (to === 'all-services') {
            await trackAction(
                FeatureTracked.OpenAllMyServices,
                actionTrackedInfo
            );
        }
    };
    return (
        <Link to={`/myservices/${to}`} onClick={onLinkClicked} {...linkProps}>
            {children}
        </Link>
    );
};
