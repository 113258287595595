import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createHighlightLayoutsFetchFailureAction,
    createHighlightLayoutsFetchRequestAction,
    createHighlightLayoutsFetchSuccessAction,
} from '../../../store/actions/highlightLayoutAction';

export const getMyHighlightLayouts = (language: string): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger }
    ) => {
        dispatch(createHighlightLayoutsFetchRequestAction());
        try {
            const myHighlightLayouts = await serviceBoardClient.getMyHighlightLayouts(language);
            dispatch(createHighlightLayoutsFetchSuccessAction(myHighlightLayouts?.layouts || []));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createHighlightLayoutsFetchFailureAction());
        }
    };
};
