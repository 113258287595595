import { Dispatch } from 'react';
import { NotificationsApi, NotificationTrackingsApi } from 'services/ApiService/Notification/NotificationApi';
import { SearchNotificationsRequest } from 'services/ApiService/Notification/NotificationApiClient';
import { ActionType } from 'store/actions/ActionType';
import {
    createTrackUserNotificationClickFetchRequestAction,
    createTrackUserNotificationClickFetchSuccessAction,
    createTrackUserNotificationClickFetchFailureAction,
    createNotificationCountByProducersFetchRequestAction,
    createNotificationCountByProducersFetchSuccessAction,
    createNotificationCountByProducersFetchFailureAction,
    createSearchNotificationsFetchRequestAction,
    createSearchNotificationsFetchSuccessAction,
    createSearchNotificationsFetchFailureAction,
    createTrackUserNotificationReadFetchRequestAction,
    createTrackUserNotificationReadFetchSuccessAction,
    createTrackUserNotificationReadFetchFailureAction,
} from 'store/Notification/sgmNotificationAction';
import { ThunkResult } from 'store/store';

export const doMarkAsClicked = (notificationId: number): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger }
    ) => {
        const { notification: { markAsClicked } } = state();
        if (markAsClicked.isFetching || !markAsClicked.didInvalidate) {
            return;
        }

        dispatch(createTrackUserNotificationClickFetchRequestAction());
        try {
            const markAsClickedResponse = await NotificationTrackingsApi.putMarkAsClicked(notificationId);
            dispatch(createTrackUserNotificationClickFetchSuccessAction(markAsClickedResponse));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createTrackUserNotificationClickFetchFailureAction());
        }
    };
};

export const getCountByProducers = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger }
    ) => {
        const { notification: { countByProducers } } = state();
        if (countByProducers.isFetching) {
            return;
        }

        dispatch(createNotificationCountByProducersFetchRequestAction());
        try {
            const countByProducersResponse = await NotificationsApi.getCountByProducers(false);
            dispatch(createNotificationCountByProducersFetchSuccessAction(countByProducersResponse?.items));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createNotificationCountByProducersFetchFailureAction());
        }
    };
};

export const searchNotifications = (searchNotificationsRequest: SearchNotificationsRequest): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger }
    ) => {
        const { notification: { searchResult: { isFetching } } } = state();
        if (isFetching) {
            return;
        }

        dispatch(createSearchNotificationsFetchRequestAction());
        try {
            const searchResponse = await NotificationsApi.search(searchNotificationsRequest);
            dispatch(createSearchNotificationsFetchSuccessAction(searchResponse));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createSearchNotificationsFetchFailureAction());
        }
    };
};

export const doMarkAsReadAll = (producerCode: Nullable<string>): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger, toaster }
    ) => {
        const { notification: { markAsRead } } = state();
        if (markAsRead.isFetching) {
            return;
        }

        dispatch(createTrackUserNotificationReadFetchRequestAction());
        try {
            await NotificationTrackingsApi.putMarkAsRead({
                allForRequester: true,
                producerCodes: producerCode ? [producerCode] : [],
            });
            dispatch(createTrackUserNotificationReadFetchSuccessAction());
        } catch (error: any) {
            toaster.toastUsingTranslationError({ messageTranslationKey: 'my-notification:dismissedErrorAlertMessage' });
            logger.error(error.message, error);
            dispatch(createTrackUserNotificationReadFetchFailureAction());
        }
    };
};