import { My_ACCOUNT_LANGUAGES, useLanguage } from 'components/hooks/LanguageProvider';
import { ActionDropdownButton, ActionDropdownItem } from '../components/ActionDropdownButton';
import { SettingListGroup, SettingListGroupItemProps } from '../components/SettingListGroup';
import { DEFAULT_LANGUAGE } from 'models/constants';

export const Preferences = () => {
    const { preferredLanguage, setPreferredLanguage } = useLanguage();
    const languageDropDownItems: ActionDropdownItem[] = My_ACCOUNT_LANGUAGES.map((langItem) => {
        const item: ActionDropdownItem = {
            key: langItem.shortName,
            label: langItem.name,
        };
        return item;
    });

    const items: SettingListGroupItemProps[] = [
        {
            titleLngKey: 'title-language',
            descriptionLngKey: 'settings-language-description',
            iconName: 'language',
            wrapInLink: false,
            actionComponent: <ActionDropdownButton
                listItems={languageDropDownItems}
                defaultSeletedItemKey={preferredLanguage || DEFAULT_LANGUAGE}
                onChange={setPreferredLanguage}
                buildSelectedLabel={(item: ActionDropdownItem) => `${item.key.toUpperCase()} - ${item.label}`}
            />,
        },
    ];
    return <SettingListGroup items={items} titleLngKey="title-preferences" />;
};