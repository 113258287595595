import {
    TrackUserNotificationClickFetchRequestActionType,
    TrackUserNotificationClickFetchSuccessActionType,
    TrackUserNotificationClickFetchFailureActionType,
    NotificationCountByProducersFetchRequestActionType,
    NotificationCountByProducersFetchSuccessActionType,
    NotificationCountByProducersFetchFailureActionType,
    SearchNotificationsFetchFailureActionType,
    SearchNotificationsFetchRequestActionType,
    SearchNotificationsFetchSuccessActionType,
    TrackUserNotificationReadFetchRequestActionType,
    TrackUserNotificationReadFetchSuccessActionType,
    TrackUserNotificationReadFetchFailureActionType,
} from 'store/Notification/sgmNotificationAction';
import {
    MarkAsClickedResponse,
    NotificationCountByProducerResponse,
    SearchNotificationsResponse,
}
    from 'services/ApiService/Notification/NotificationApiClient';
import { ApiRequest, Reducer } from 'store/store';
import { initialApiRequest } from 'store/store-tools';

export const initialState: {
    markAsClicked: ApiRequest<MarkAsClickedResponse> & { neverFetched: boolean },
    countByProducers: ApiRequest<NotificationCountByProducerResponse[]>,
    searchResult: ApiRequest<SearchNotificationsResponse> & { neverFetched: boolean },
    markAsRead: ApiRequest<void>,
} = {
    markAsClicked: {
        ...initialApiRequest(),
        neverFetched: true,
    },
    countByProducers: {
        ...initialApiRequest(),
    },
    searchResult: {
        ...initialApiRequest(),
        neverFetched: true,
    },
    markAsRead: {
        ...initialApiRequest(),
    },
} as const;

export type NotificationState = typeof initialState;
export const NotificationReducer: Reducer<NotificationState> = (state = initialState, action) => {
    switch (action.type) {
        case TrackUserNotificationClickFetchRequestActionType:
            return {
                ...state,
                markAsClicked: {
                    ...state.markAsClicked,
                    isFetching: true,
                    didInvalidate: false,
                    hasError: false,
                    neverFetched: false,
                },
            };
        case TrackUserNotificationClickFetchSuccessActionType:
            return {
                ...state,
                markAsClicked: {
                    ...state.markAsClicked,
                    isFetching: false,
                    didInvalidate: false,
                    hasError: false,
                    neverFetched: false,
                    data: action.payload,
                },
            };
        case TrackUserNotificationClickFetchFailureActionType:
            return {
                ...state,
                markAsClicked: {
                    ...state.markAsClicked,
                    isFetching: false,
                    didInvalidate: true,
                    hasError: true,
                    neverFetched: false,
                },
            };
        case NotificationCountByProducersFetchRequestActionType:
            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    isFetching: true,
                    didInvalidate: false,
                    hasError: false,
                },
            };
        case NotificationCountByProducersFetchSuccessActionType:
            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    isFetching: false,
                    didInvalidate: false,
                    hasError: false,
                    data: action.payload,
                },
            };
        case NotificationCountByProducersFetchFailureActionType:
            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    isFetching: false,
                    didInvalidate: true,
                    hasError: true,
                },
            };
        case SearchNotificationsFetchRequestActionType:
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    isFetching: true,
                    didInvalidate: false,
                    hasError: false,
                    neverFetched: false,
                },
            };
        case SearchNotificationsFetchSuccessActionType:
            return {
                ...state,
                searchResult: {
                    data: mergeSearchResult(state.searchResult.data, action.payload),
                    isFetching: false,
                    didInvalidate: false,
                    hasError: false,
                    neverFetched: false,
                },
            };
        case SearchNotificationsFetchFailureActionType:
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    isFetching: false,
                    didInvalidate: true,
                    hasError: true,
                    neverFetched: false,
                },
            };

        case TrackUserNotificationReadFetchRequestActionType:
            return {
                ...state,
                markAsRead: {
                    ...state.markAsRead,
                    isFetching: true,
                    didInvalidate: false,
                    hasError: false,
                },
            };
        case TrackUserNotificationReadFetchSuccessActionType:
            return {
                ...state,
                countByProducers: {
                    ...state.countByProducers,
                    didInvalidate: true,
                },
                searchResult: {
                    ...state.searchResult,
                    didInvalidate: true,
                },
                markAsRead: {
                    ...state.markAsRead,
                    isFetching: false,
                    didInvalidate: false,
                    hasError: false,
                },
            };
        case TrackUserNotificationReadFetchFailureActionType:
            return {
                ...state,
                markAsRead: {
                    ...state.markAsRead,
                    isFetching: false,
                    didInvalidate: true,
                    hasError: true,
                },
            };
        default:
            return state;
    }
};

const mergeSearchResult = (oldState: SearchNotificationsResponse | undefined, newState: SearchNotificationsResponse): SearchNotificationsResponse => {
    const isPageSuite = (newState.pageMeta?.page || 1) > (oldState?.pageMeta?.page || 1);
    let result: SearchNotificationsResponse = {} as SearchNotificationsResponse;
    if (isPageSuite) {
        result = {
            notifications: (oldState?.notifications ?? [])?.concat(newState?.notifications ?? []),
            pageMeta: { ...oldState?.pageMeta, ...newState?.pageMeta },
        } as SearchNotificationsResponse;
    }
    return isPageSuite ? result : newState;
};