import { FC, useEffect, useState } from 'react';
import { changePreferredLanguage, changeAvailableLanguages } from 'core/services/language/languageService';
import { getConfiguration } from 'core/services/common/configuration/configurationLoader';
import { fetcher } from 'core/services/common/http/fetcher';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { DEFAULT_LANGUAGE } from 'models/constants';

const getLanguage = (app: AppState): { preferredLanguage: Nullable<string>, availableLanguages: string[] } => ({
    preferredLanguage: app.applicationContext.context?.preferredLanguage,
    availableLanguages: app.applicationContext.availableLanguages,
});

const getContextIsLoading = (state: AppState): boolean =>
    state.applicationContext.isLoading;

type UseLanguageResponse = {
    preferredLanguage: Nullable<string>;
    websiteLanguage: string;
    availableLanguages: string[];
    setPreferredLanguage: (language: string) => void;
};

export type languages = 'en' | 'fr' | 'es' | 'de' | 'ja' | 'zh' | 'it' | 'pt';
export const EN_FR_LANGUAGES: languages[] = ['fr', DEFAULT_LANGUAGE];
export const ALL_LANGUAGES: languages[] = ['de', 'fr', DEFAULT_LANGUAGE, 'es', 'ja', 'zh'];
export interface LanguageItem {
    shortName: languages;
    name: string;
  }

export const My_ACCOUNT_LANGUAGES: LanguageItem[] = [
    { shortName: DEFAULT_LANGUAGE, name: 'English' },
    { shortName: 'fr', name: 'Français' },
    { shortName: 'es', name: 'Español' },
    { shortName: 'de', name: 'Deutsch' },
    { shortName: 'it', name: 'Italian ' },
    { shortName: 'pt', name: 'Portuguese' },
    { shortName: 'ja', name: '日本語' },
    { shortName: 'zh', name: '简体中文' },
  ];
  
export const LanguageProvider: FC = () => {
    const { websiteLanguage } = useLanguage();
    const { serviceBoardUrl } = getConfiguration();
    const { i18n } = useTranslation();
    const contextIsLoading = useSelector(getContextIsLoading);

    useEffect(() => {
        if (contextIsLoading) {
            return;
        }
        if (!i18n.getResourceBundle(websiteLanguage, 'service')) {
            fetcher<any>(
                `${serviceBoardUrl}/v2/services/translate/${websiteLanguage}`,
                'GET'
            ).then((data) => {
                i18n.addResources(websiteLanguage, 'service', data);
                i18n.changeLanguage(websiteLanguage);
            });
        } else if (i18n.language !== websiteLanguage) {
            i18n.changeLanguage(websiteLanguage);
        }
    }, [i18n, serviceBoardUrl, contextIsLoading, websiteLanguage]);

    return null;
};

export const LanguageEnFrOnly: FC = () => {
    const dispatch = useDispatch();
    const { availableLanguages } = useLanguage();
    const originalLanguages = useState(availableLanguages)[0];

    useEffect(() => {
        dispatch<any>(changeAvailableLanguages(EN_FR_LANGUAGES));
        return () => {
            dispatch<any>(changeAvailableLanguages(originalLanguages));
        };
    }, [dispatch, originalLanguages]);

    return null;
};

export const useLanguage = (): UseLanguageResponse => {
    const dispatch = useDispatch();
    const { preferredLanguage, availableLanguages } = useSelector(getLanguage, shallowEqual);

    const websiteLanguage = availableLanguages
        .find(x => x === preferredLanguage?.toLocaleLowerCase()) ?? DEFAULT_LANGUAGE;

    const setPreferredLanguage = useCallback((language: string) => {
        dispatch<any>(changePreferredLanguage(language));
    }, [dispatch]);

    return {
        preferredLanguage,
        websiteLanguage,
        availableLanguages,
        setPreferredLanguage,
    };
};