import classNames from 'classnames';
import { GetNotificationResponse, NotificationKind } from 'services/ApiService/Notification/NotificationApiClient';
import { useTranslation } from 'react-i18next';
import { formatFromNow } from 'services/date';
import { DefaultTooltip } from 'components/common/tooltip/Tooltip';
import { NotificationItemMobile } from './NotificationItemMobile';
import { NotificationItemDesktop } from './NotificationItemDesktop';

export const NotificationItem = ({ notif }: { notif: GetNotificationResponse; }) => (
    <>
        <NotificationItemDesktop key={`notificationItem_${notif.id}`} notif={notif} />
        <NotificationItemMobile key={`notificationItemMobile_${notif.id}`} notif={notif} />
    </>
);

export const UnreadIcon = ({ isRead, isExpired }: { isRead: boolean, isExpired: boolean }) => {
    const { t: translate } = useTranslation('my-notification');
    const ariaTextIcon = translate('ariaTextUnread');

    return (
        <DefaultTooltip
            text={ariaTextIcon}
            placement="left"
            enabled={true}
        >
            <div className={classNames('btn btn-flat-info border-radius-circle flex-sm-center p-0 p-sm-2', {
                'invisible': isRead || isExpired,
            })} aria-label={ariaTextIcon} >
                <span className="bg-info border-radius-circle" style={{
                    height: '8px',
                    width: '8px',
                }}>
                </span>
            </div>
        </DefaultTooltip>
    );
};

export const ActionToDoIcon = ({ kind, isExpired }: { kind: NotificationKind, isExpired: boolean }) => {
    const { t: translate } = useTranslation('my-notification');
    const shouldShow = kind === NotificationKind.Action || isExpired;
    const tooltipText = isExpired ? translate('tooltipIsExpired') : translate('tooltipActionRequired');

    return (
        <DefaultTooltip
            text={tooltipText}
            placement="left"
            enabled={shouldShow}
        >
            <span className={classNames('bg-opacity-10 border-radius-circle flex-center pe-auto', {
                'invisible': !shouldShow,
                'text-warning bg-warning': !isExpired,
                'text-secondary bg-secondary': isExpired,
            })} aria-label={translate('ariaTextRequiresAction')}>
                <i className="icon icon-xs lh-1">priority_high</i>
            </span>
        </DefaultTooltip>
    );
};


export const SummaryElement = ({ category, payload, isExpired }
    : { category: string | undefined, payload: string | undefined, isExpired: boolean }) => (
    <div className={classNames('d-grid', {
        'text-secondary': isExpired,
    })}>
        <div className="fw-semibold text-truncate">{category}</div>
        <div className="fw-normal text-truncate">{payload}</div>
    </div>
);

export const DurationElement = ({ createdDate }: { createdDate: Date | undefined }) => {
    const { i18n: { language } } = useTranslation('my-notification');

    return (
        <div className="text-secondary font-weight-medium mb-0 text-end flex-nowrap">
            {formatFromNow(createdDate, language)}
        </div>
    );
};