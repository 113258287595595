import { FC, useEffect } from 'react';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { ISignatureForm } from './EditSignature';
import { useBannerTemplate } from 'components/hooks/banner/useBanner';
import { useDispatch } from 'react-redux';
import { getBannerTemplate } from 'core/services/banner/bannerService';
import { useTranslation } from 'react-i18next';
import { AccountSignatureSkeleton } from './AccountSignatureSkeleton';

type PreviewSignatureProps = {
    watch: UseFormWatch<ISignatureForm>
}

const minHeight = 300;

export const PreviewSignature: FC<PreviewSignatureProps> = ({ watch }) => {
    const allFields = watch();
    const dispatch = useDispatch();
    const { t: translate } = useTranslation('signature');

    useEffect(() => {
        if (allFields.templateUrl) {
            dispatch<any>(getBannerTemplate(allFields.templateUrl));
        }
    }, [allFields.templateUrl]);

    const { loading, templateContent } = useBannerTemplate(allFields.templateUrl);
    const compiledTemplate = compile(templateContent, allFields);

    if (loading) {
        return <div style={{ minHeight }} className="mt-2">
            <AccountSignatureSkeleton />
        </div>;
    }

    if (!allFields.bannerId) {
        return <div style={{ minHeight }} className="d-flex mt-2 align-items-center justify-content-center">
            {translate('signature:pleaseChooseBanner')}
        </div>;
    }

    return <div
        style={{ minHeight }}
        className="mt-2 d-flex flex-column overflow-hidden"
        dangerouslySetInnerHTML={{ __html: compiledTemplate || '' }}
    />;
};

const compile = (template: string | undefined, model: FieldValues): string | null => {
    if (template) {
        let content = ifCompile(template, model);

        const matches = template.match(/\[\s*(.*?)\s*\]/gi);

        if (!matches) {
            return content;
        }

        for (const match of matches) {
            // Extract property name by removing brackets
            const propertyName = match.substring(1, match.length - 1);

            if (hasProperty(model, propertyName)) {
                if (hasPropertyValue(model, propertyName)) {
                    content = content.replace(match, replaceLineBreak(getPropertyValue(model, propertyName)));
                } else {
                    content = content.replace(match, '');
                }
            }
        }

        return content;
    }

    return null;
};

const ifCompile = (template: string, model: FieldValues): string => {
    const regex = /\[IF\((.*?)\)](.*\[\/IF\]*?|[\s\S]*?|.*?)\[\/IF\]/gi;
    let match = regex.exec(template);
    let content = template;
    do {
        if (match === null) {
            return content;
        }

        let propertyName = match[1] as string;
        let inversed = false;

        if (propertyName.trim().startsWith('!')) {
            propertyName = propertyName.substring(1);
            inversed = true;
        }

        if (hasPropertyValue(model, propertyName, inversed)) {
            content = content.replace(match[0], match[2]);
        } else {
            content = content.replace(match[0], '');
        }
        match = regex.exec(template);
    } while (match !== null);

    return ifCompile(content, model);
};

const hasPropertyValue = (instance: { [index: string]: any }, property: string, inversed = false): boolean => {
    const value = getPropertyValue(instance, property);
    return +!!value !== +inversed;
};

const hasProperty = (instance: { [index: string]: any }, property: string): boolean => {
    return !!Object.keys(instance).find(x => x.toLowerCase() === property.toLowerCase());
};

const getPropertyValue = (instance: { [index: string]: any }, property: string): any => {
    // case insensitive property
    const matchedProperty = Object.keys(instance).find(x => x.toLowerCase() === property.toLowerCase());
    return matchedProperty ? instance[matchedProperty] : undefined;
};

const replaceLineBreak = (text: string) => {
    if (!text) {
        return text;
    }

    return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
};
