import { FC } from 'react';
import { Scroller } from 'components/common/scroll/Scroller';
import { ServiceCard } from 'components/common/service-card/ServiceCard';
import { useCatalogServices } from 'components/hooks/services/useBoardServices';
import { CATALOG_BROWSE_PATH, MARKETS_CATEGORY } from 'models/constants';
import { groupBy, sortBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Fragment, useCallback } from 'react';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { extractCategoryKey } from 'components/hooks/services/useCategories';
import { ActionTrackingInfoProvider, useActionTrackingContext } from 'components/common/tracking/serviceTrackingContext';
import { ActionTrackedInfo, FeatureTracked } from 'models/tracking';
import { CatalogBrowseSubCategoryFilter, allSubCategoryKey } from '../CatalogBrowseSubCategoryFilter';
import { CategoryFirstHighlightLayout } from '../CategoryFirstHighlightLayout';

interface CatalogBrowseMarketsProps {
    subCategory: Nullable<string>;
}

const subCategoryOrder: { [key: string]: number } = {
    'services.subcategory.pretrade': 1,
    'services.subcategory.pricingExecution': 2,
    'services.subcategory.posttrade': 3,
};

const sortSubCategories = (keys: string[]): string[] => {
    return sortBy(keys, x => subCategoryOrder[x] ?? x);
};

export const CatalogBrowseMarkets: FC<CatalogBrowseMarketsProps> = ({ subCategory }) => {
    const { t: translate } = useTranslation('catalog');
    const navigate = useNavigate();
    const { trackAction } = useServiceTracking();
    const { actionTrackedInfo } = useActionTrackingContext();
    const { visibleServices } = useCatalogServices();

    const boardServices = sortBy(visibleServices?.filter(x => x.Category?.Key === MARKETS_CATEGORY) || [], x => subCategoryOrder[x.Subcategory?.Key ?? ''] ?? x);
    const marketCategory = boardServices?.[0].Category || { Key: MARKETS_CATEGORY };
    const activeSubCategory = boardServices.find(x => x.Subcategory?.Key === subCategory)?.Subcategory || { Key: allSubCategoryKey };
    const isAllFilter = activeSubCategory.Key === allSubCategoryKey;
    const subServices = boardServices.filter(x => activeSubCategory.Key === allSubCategoryKey || x.Subcategory?.Key === activeSubCategory.Key) || [];

    const servicesBySubCategories = groupBy(subServices, x => x.Subcategory?.Key);

    const trackInfos = useCallback((category: {
        Key?: string | null;
        Name?: string | null;
    }, subcategory: {
        Key?: string | null;
        Name?: string | null;
    }): ActionTrackedInfo => {
        return {
            topic: actionTrackedInfo.topic,
            parameters: {
                'category-key': category.Key || '',
                'category-label': category.Name || '',
                'sub-category-key': subcategory.Key || '',
                'sub-category-label': subcategory.Name || '',
            },
        };
    }, [actionTrackedInfo.topic]);

    const handleViewAllClick = useCallback(async (category: {
        Key?: string | null;
        Name?: string | null;
    }, subcategory: {
        Key?: string | null;
        Name?: string | null;
    }) => {
        navigate(`${CATALOG_BROWSE_PATH}/${extractCategoryKey(MARKETS_CATEGORY)}/${extractCategoryKey(subcategory.Key)}`);
        await trackAction(FeatureTracked.ViewAllMarketsSubCategory, trackInfos(category, subcategory));
    }, [trackAction, navigate, trackInfos]);

    return <>
        <CategoryFirstHighlightLayout activeCategory={marketCategory} />
        <CatalogBrowseSubCategoryFilter
            activeCategory={marketCategory}
            activeSubCategory={activeSubCategory}
            categoryServices={boardServices}
            trackInfos={trackInfos}
        />
        {
            sortSubCategories(Object.keys(servicesBySubCategories)).map((subCategoryKey) => {
                let subCategoryServices = servicesBySubCategories[subCategoryKey];
                subCategoryServices = isAllFilter ? subCategoryServices.slice(0, 4) : subCategoryServices;

                const subCategory = subCategoryServices?.at(0)?.Subcategory || { Key: subCategoryKey };
                return (
                    <Fragment key={subCategoryKey}>
                        {isAllFilter ?
                            <>
                                <div className="d-flex align-items-center justify-content-between mt-4">
                                    <h3>{subCategory?.Name}</h3>
                                    <button className="btn btn-lg btn-icon-end btn-flat-secondary"
                                        onClick={async () => await handleViewAllClick(marketCategory, subCategory)}>
                                        {translate('catalog:viewAll')}<i className="icon">arrow_forward_ios</i>
                                    </button>
                                </div>
                                <div className="mb-3 mb-md-4 mb-lg-5">
                                    <Scroller className="row g-2 g-md-3 py-3 flex-nowrap">
                                        {subCategoryServices.map((s) =>
                                            <div className="col-6 col-md-4 col-lg-3" key={s.ServiceKey}>
                                                <ActionTrackingInfoProvider actionTrackedInfo={trackInfos(marketCategory, subCategory)} >
                                                    <ServiceCard service={s} />
                                                </ActionTrackingInfoProvider>
                                            </div>
                                        )}
                                    </Scroller>
                                </div>
                            </>
                            :
                            <div className="mb-3 mb-md-4 mb-lg-5 mt-3">
                                <div className="row g-2 g-md-3 g-lg-3 py-3">
                                    {subCategoryServices.map((s) =>
                                        <div className="col-6 col-md-4 col-lg-3" key={s.ServiceKey}>
                                            <ActionTrackingInfoProvider actionTrackedInfo={trackInfos(marketCategory, subCategory)} >
                                                <ServiceCard service={s} />
                                            </ActionTrackingInfoProvider>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </Fragment>
                );
            })
        }
    </>;
};
