import { FC } from 'react';
import { ProducerInfo, ProducerUniverse } from 'services/ApiService/Notification/NotificationApiClient';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export type ProducerItemProps = {
    producer: ProducerInfo | undefined,
    withSmallFontSize?: boolean,
    isForExpiredNotification?: boolean,
};

export const ProducerItem: FC<ProducerItemProps> = ({ producer, withSmallFontSize, isForExpiredNotification }) => {
    const { t: translate } = useTranslation('my-notification');
    const ariaTextIcon = translate('ariaTextCountByProducerIcon');
    
    if (!producer) {
        return false;
    }

    const isSgmUniverse = producer.universe === ProducerUniverse.SgMarkets;
    return (
        <div className="d-flex align-items-center">
            <div className={classNames('fw-semibold text-dark me-2 d-flex border border-opacity-40 border-radius-4 overflow-hidden', {
                'bg-lvl1': isSgmUniverse,
                'bg-lvl3  text-secondary': !isSgmUniverse,
                'fs-12': withSmallFontSize,
            })}
                style={{
                    minWidth: '24px',
                    minHeight: '24px',
                }} aria-label={ariaTextIcon} title={ariaTextIcon}>
                <span className="m-auto">{producer.initials}</span>
            </div>
            <div style={{
                maxWidth: '130px',
            }}
                className={classNames('font-weight-normal fw-medium text-truncate', {
                    'fs-12': withSmallFontSize,
                    'text-primary': !isForExpiredNotification,
                    'text-secondary': isForExpiredNotification,
                })}>{producer.name}</div>
        </div>
    );
};
