import { FC } from 'react';
import { Footer } from 'components/layout/footer/Footer';
import { ServiceboardHeader } from 'components/layout/header/ServiceboardHeader';
import { ProducerMenu } from './Producer/ProducerMenu';
import { Notifications } from './Notifications';

import './MyNotificationPage.scss';

export const MyNotificationsPage: FC = () => {

    return <>
        <ServiceboardHeader minimalHeader={true} />
        <div className="d-flex flex-column flex-md-row flex-grow-1 border-top border-opacity-40">
            <div className="col-md-1 col-lg-3 col-xl-2 d-flex">
                <ProducerMenu />
            </div>
            <div className="col-md-11 col-lg-9 col-xl-10 d-flex flex-column flex-grow-1">
                <Notifications />
                <Footer />
            </div>
        </div>
    </>;
};
