import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetSettings } from 'core/services/myAccount/myAccountService';
import { SecuritySettings } from './SecuritySettings';
import { Preferences } from './Preferences';
import { ServiceSettings } from './ServiceSettings';
import { NotificationSettings } from './NotificationSettings';
import { useMyAccountSetting } from 'components/hooks/myAccount/useMyAccountSetting';
import Skeleton from 'react-loading-skeleton';

import './AccountSettings.scss';
import { SkeletonWrapper } from 'components/common/SkeletonWrapper';

export const AccountSettings: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch<any>(GetSettings());
    }, [dispatch]);
    const { isLoading } = useMyAccountSetting();

    return <div className="my-5 mx-4 mx-lg-5">
        {isLoading
            ? <AccountSettingsSkeleton />
            : (<>
                <div className="pb-3">
                    <SecuritySettings />
                </div>
                <div className="py-3">
                    <Preferences />
                </div>
                <div className="py-3">
                    <ServiceSettings />
                </div>
                <div className="pt-3">
                    <NotificationSettings />
                </div>
            </>)
        }
    </div>;
};

const AccountSettingsSkeleton = () => {
    return (
        <SkeletonWrapper>
            <Skeleton width={180} height={50} className="mb-1" />
            <Skeleton height={76} className="mb-4" />
            <Skeleton width={180} height={50} className="mb-1" />
            <Skeleton height={76} className="mb-4" />
        </SkeletonWrapper>
    );
};