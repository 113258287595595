import classNames from 'classnames';
import { GetNotificationResponse } from 'services/ApiService/Notification/NotificationApiClient';
import { ProducerItem } from './Producer/ProducerItem';
import { UnreadIcon, ActionToDoIcon, SummaryElement, DurationElement } from './NotificationItem';


export const NotificationItemDesktop = ({ notif }: { notif: GetNotificationResponse; }) => (
    <a href={notif.url} target="_blank" rel="noopener noreferrer"
        className={classNames('border-bottom border-opacity-40 list-group-item list-group-item-action p-0 d-none d-sm-block', {
            'bg-lvl2': notif.isRead,
            'pe-none': notif.isExpired,
        })}>
        <div className="d-flex align-items-sm-center px-3 notif-custom-space-t-12px notif-custom-space-b-12px"
            style={{ minHeight: '64px' }}>
            <div className="my-sm-auto">
                <UnreadIcon isRead={notif.isRead} isExpired={notif.isExpired} />
            </div>
            <div className="ms-3 p-1">
                <ActionToDoIcon kind={notif.kind} isExpired={notif.isExpired} />
            </div>
            <div className="d-flex align-items-sm-center flex-grow-1 notif-custom-space-s-12px">
                <SummaryElement category={notif.category} payload={notif.payload} isExpired={notif.isExpired} />
            </div>
            <div className="d-flex align-items-sm-center justify-content-between"
                style={{ minWidth: '270px', maxWidth: '270px' }}
            >
                <div className="ms-3">
                    <ProducerItem producer={notif.producer} isForExpiredNotification={notif.isExpired} />
                </div>
                <div className="ms-3">
                    <DurationElement createdDate={notif.createdDate} />
                </div>
            </div>
        </div>
    </a>
);
