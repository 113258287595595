import { LearnMoreItem } from 'models/learnMoreItem';

export const LearnMoreItemFetchRequestActionType = '@LEARN_MORE/FETCH_REQUEST';
export const LearnMoreItemFetchSuccessActionType = '@LEARN_MORE/FETCH_SUCCESS';
export const LearnMoreItemFetchFailureActionType = '@LEARN_MORE/FETCH_FAILURE';

export type LearnMoreItemFetchRequestAction = ReduxAction<
    typeof LearnMoreItemFetchRequestActionType
>;
export type LearnMoreItemFetchSuccessAction = ReduxAction<
    typeof LearnMoreItemFetchSuccessActionType,
    { payload: Nullable<LearnMoreItem[]> }
>;
export type LearnMoreItemFetchFailureAction = ReduxAction<
    typeof LearnMoreItemFetchFailureActionType
>;

export const createLearnMoreItemFetchRequestAction = (): LearnMoreItemFetchRequestAction => ({
    type: LearnMoreItemFetchRequestActionType,
});

export function createLearnMoreItemFetchSuccessAction(
    user: Nullable<LearnMoreItem[]>
): LearnMoreItemFetchSuccessAction {
    return {
        type: LearnMoreItemFetchSuccessActionType,
        payload: user,
    };
}

export const createLearnMoreItemFetchFailureAction = (): LearnMoreItemFetchFailureAction => ({
    type: LearnMoreItemFetchFailureActionType,
});

export type LearnMoreItemAction =
    | LearnMoreItemFetchRequestAction
    | LearnMoreItemFetchSuccessAction
    | LearnMoreItemFetchFailureAction;
