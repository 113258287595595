import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clipPaginateList } from 'components/pages/search/clip-list';
import { SeeMoreLinkSgm } from 'components/pages/search/SeeMoreLink';
import { GetSearchResponse, SearchResultType } from 'services/ApiService/SgmSearch/SgmSearchApiClient';
import { ServiceSearchItemCompactList, getSgmItemRequestAccessInfo } from './ServiceSearchItemCompactList';
import { EmptyLinePlaceholder } from 'components/common/EmptyLinePlaceholder';

const MAX_SGM_SERVICES = 3;
type QuicksearchSgmResultsProps = {
    sgmSearchResultItems: NonNullable<Required<GetSearchResponse['sgm']>>;
    isLoading: boolean;
    openInNew: boolean;
};

export const QuicksearchSgmResults: FC<QuicksearchSgmResultsProps> = ({
    sgmSearchResultItems,
    isLoading,
    openInNew,
}) => {
    const { t: translate } = useTranslation('service-list');
    const { clipped, total, hasMore } = clipPaginateList(sgmSearchResultItems, MAX_SGM_SERVICES);

    if (!isLoading && clipped.length === 0) {
        return (
            <p className="quicksearch-title text-secondary">
                {translate('service-list:noResultsFound')}
            </p>
        );
    }

    return (
        <div>
            <h4 className="quicksearch-title text-secondary">
                {translate('service-list:sgmServices')}
            </h4>
            {isLoading
                ? <EmptyLinePlaceholder count={MAX_SGM_SERVICES} />
                : (
                    <>
                        <ServiceSearchItemCompactList
                            serviceSearchItemLineInfos={clipped.map((sgmItem) => {
                                return {
                                    referenceId: sgmItem.referenceId,
                                    title: sgmItem.title,
                                    url: sgmItem.url,
                                    category: sgmItem.category,
                                    initials: sgmItem.initials,
                                    isInternal: sgmItem.isInternal,
                                    searchItemSource: sgmItem.sourceInfos?.at(0)?.source,
                                    searchType: SearchResultType.SgmItem,
                                    description: sgmItem.description,
                                    relatedToRelationType: sgmItem.sourceInfos?.at(0)?.relatedTo?.relationType,
                                    requestAccessInfo: getSgmItemRequestAccessInfo(sgmItem),
                                };
                            })}
                        />
                        {hasMore && <SeeMoreLinkSgm total={total} openInNew={openInNew} />}
                    </>
                )
            }
        </div>
    );
};
