import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export type LocalizedObject<T> = {
    en?: Nullable<T>
};

type IndexedLocalizedObject<T> = LocalizedObject<T>  & {
    [language: string]: Nullable<T>
};

type KeyBasedLocalization = <TValue>(
    localizedObject: LocalizedObject<TValue>
) => Nullable<TValue>;

export const useLocalizedObject = (language?: string): KeyBasedLocalization => {
    const { i18n } = useTranslation();

    return useCallback(
        <T,>(
            localizedObject: LocalizedObject<T>
        ): Nullable<T> => {
            const chosenLanguage = (language || i18n.language).toLowerCase();
            if (localizedObject.hasOwnProperty(chosenLanguage)) {
                return (localizedObject as IndexedLocalizedObject<T>)[chosenLanguage] as Nullable<T>;
            }
            return localizedObject.en;
        },
        [language, i18n.language]
    );
};
