import { FC, useState } from 'react';
import classNames from 'classnames';
import { handleA11y } from 'core/utils/a11y';

export type ActionDropdownItem = {
    key: string;
    label: string;
}

export type ActionDropdownButtonProps = {
    listItems: ActionDropdownItem[],
    defaultSeletedItemKey: string,
    onChange?: (activeItem: string) => void,
    buildSelectedLabel?: (selectedItem: ActionDropdownItem) => string,
};

export const ActionDropdownButton: FC<ActionDropdownButtonProps> = ({ listItems, defaultSeletedItemKey, onChange, buildSelectedLabel }) => {
    const [selectedItemKey, setSelectedItemKey] = useState<string>(defaultSeletedItemKey);

    if (!listItems || listItems.length == 0) {
        return <></>;
    }

    const getOptionSelectedLabel = (): string => {
        const selectedItem = listItems.find(item => item.key === defaultSeletedItemKey);
        if (selectedItem)
            return buildSelectedLabel ? buildSelectedLabel(selectedItem) : selectedItem.label;
        else
            return listItems[0].label;
    };

    const handleItemChange = (activeItemKey: string): void => {
        setSelectedItemKey(activeItemKey);

        if (onChange) {
            onChange(activeItemKey);
        }
    };

    return (
        <div className="btn-group">
            <button className="btn btn-flat-primary btn-lg dropdown-toggle m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {getOptionSelectedLabel()}
            </button>
            <ul className="dropdown-menu">
                {listItems.map((item) => {
                    return <li
                        key={item.key}
                        id={`myAccountActionDropdown_${item.key}`}
                    >
                        <div
                            className={classNames('dropdown-item pointer', { 'active': selectedItemKey === item.key })}
                            role="option"
                            onClick={handleA11y(() => handleItemChange(item.key))}
                            onKeyUp={handleA11y(() => handleItemChange(item.key))}
                            aria-selected={selectedItemKey === item.key}
                            tabIndex={0}
                        >
                            {item.label}
                        </div>
                    </li>;
                })}
            </ul>
        </div>
    );
};
