import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export type SettingListGroupItemProps = {
    title?: string,
    titleLngKey: string,
    description?: string,
    descriptionLngKey: string,
    iconName: string,
    iconColor?: string,
    initials?: string,
    actionComponent: ReactNode,
    wrapInLink: boolean,
    itemUrl?: string
};

export type SettingListGroupProps = {
    titleLngKey: string;
    items: SettingListGroupItemProps[]
};

export const SettingListGroup: FC<SettingListGroupProps> = ({ titleLngKey, items }) => {
    const { t: translate } = useTranslation('my-account');
    if (!items || items.length === 0) {
        return <></>;
    }

    return (
        <>
            <h3 className="h3">
                {translate(titleLngKey)}
            </h3>
            <ul className="list-group">
                {items.map((item, index) => {
                    const iconColor = item.iconColor || 'text-secondary';
                    return (
                        <li key={index}
                            className={classNames('list-group-item list-group-item-action border-top border-start border-end border-opacity-40 d-flex align-items-center ',
                                {
                                    'border-bottom': index === items.length - 1,
                                    'not-link': !item.wrapInLink,
                                })}
                        >
                            {item.wrapInLink && item.itemUrl
                                ? <a className="d-flex align-items-center flex-grow-1 text-decoration-none" href={item.itemUrl} target="_blank" rel="noreferrer">
                                    <ItemContent item={item} iconColor={iconColor} />
                                </a>
                                : <ItemContent item={item} iconColor={iconColor} />
                            }
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

type ItemContentProps = {
    iconColor: string;
    item: SettingListGroupItemProps
};

const ItemContent: FC<ItemContentProps> = ({ item, iconColor }) => {
    const { t: translate } = useTranslation('my-account');

    return <>
        {item.iconName
            ? <i className={`icon icon-md ${iconColor}`}>{item.iconName}</i>
            : <div className="d-inline-flex d-flex justify-content-center align-items-center all-services-initials-icon border p-0 text-black bg-white">
                <span className="display-6 fw-medium overflow-hidden">{item.initials}</span>
            </div>
        }
        <div className="mx-3 flex-grow-1">
            <div className="fw-medium text-primary">{item.title ? item.title : translate(item.titleLngKey)}</div>
            <div className="text-secondary">{item.description ? item.description : translate(item.descriptionLngKey)}</div>
        </div>
        {item.actionComponent}
    </>;
};