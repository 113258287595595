import { DisclaimerContent } from 'models/disclaimerModels';
import {
    GenerateUserDisclaimerFetchRequestActionType,
    GenerateUserDisclaimerFetchSuccessActionType,
    GenerateUserDisclaimerFetchFailureActionType,
    LastValidatedUserDisclaimerFetchRequestActionType,
    LastValidatedUserDisclaimerFetchSuccessActionType,
    LastValidatedUserDisclaimerFetchFailureActionType,
} from 'store/actions/disclaimerAction';
import { ActionType } from 'store/actions/ActionType';

interface IDisclaimerServiceState {
    lastValidatedContent: {
        isLoading: boolean,
        hasError: boolean,
        data: Nullable<DisclaimerContent>
    },
    generatedContent: {
        isLoading: boolean,
        hasError: boolean,
        data: Nullable<string>
    }
}

const services = (
    state: IDisclaimerServiceState,
    action: ActionType
): IDisclaimerServiceState => {
    state ??= {
        lastValidatedContent: {
            isLoading: false,
            hasError: false,
            data: undefined,
        },
        generatedContent: {
            isLoading: false,
            hasError: false,
            data: undefined,
        },
    };

    switch (action.type) {
        case GenerateUserDisclaimerFetchRequestActionType:
            return {
                ...state,
                generatedContent: {
                    ...state.generatedContent,
                    isLoading: true,
                    hasError: false,
                },
            };
        case GenerateUserDisclaimerFetchSuccessActionType:
            return {
                ...state,
                generatedContent: {
                    ...state.generatedContent,
                    isLoading: false,
                    hasError: false,
                    data: action.payload,
                },
            };
        case GenerateUserDisclaimerFetchFailureActionType:
            return {
                ...state,
                generatedContent: {
                    ...state.generatedContent,
                    isLoading: false,
                    hasError: true,
                },
            };

        case LastValidatedUserDisclaimerFetchRequestActionType:
            return {
                ...state,
                lastValidatedContent: {
                    ...state.lastValidatedContent,
                    isLoading: true,
                    hasError: false,
                },
            };
        case LastValidatedUserDisclaimerFetchSuccessActionType:
            return {
                ...state,
                lastValidatedContent: {
                    ...state.lastValidatedContent,
                    isLoading: false,
                    hasError: false,
                    data: action.payload,
                },
            };
        case LastValidatedUserDisclaimerFetchFailureActionType:
            return {
                ...state,
                lastValidatedContent: {
                    ...state.lastValidatedContent,
                    isLoading: false,
                    hasError: true,
                },
            };
        default:
            return state;
    }
};

export default services;
