import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type ContentCheckedProps = {
    content?: string | null;
};

export const ContentChecked: FC<ContentCheckedProps> = ({ content }) => {
    return <>
        {content ? <div>
            {content}
        </div> : null
        }
    </>;
};

export type LabeledContentCheckedProps = {
    content?: string | null;
    translationKey: string;
};

export const LabeledContentChecked: FC<LabeledContentCheckedProps> = ({ content, translationKey }) => {
    const { t: translate } = useTranslation('my-account');
    return <>
        {content ? <div className="row">
            <div className="col text-secondary">{translate(translationKey)}</div>
            <div className="col">{content}</div>
        </div> : null
        }
    </>;
};