import { FC } from 'react';
import { AccountPersonalInformation } from './accountPersonalInformation/AccountPersonalInformation';
import { AccountSettings } from './accountSettings/AccountSettings';
import { AccountSignature } from './accountSignature';
import { AccountNotice } from './accountNotice/AccountNotice';
import { MyAccountTabs, useActiveTab } from './MyAccountTabs';
import { useTranslation } from 'react-i18next';
import { Footer } from 'components/layout/footer/Footer';
import { ServiceboardHeader } from 'components/layout/header/ServiceboardHeader';

export const MyAccountPage: FC = () => {
    const { activeTab } = useActiveTab();
    const { t: translate } = useTranslation('my-account');

    return <>
        <ServiceboardHeader minimalHeader={true} />
        <div className="d-flex flex-grow-1">
            <div className="col-lg-4 d-lg-block d-none">
                <AccountPersonalInformation />
            </div>
            <div className="col-lg-8 col-12 d-flex flex-column">
                <div className="h2 mx-lg-5 mx-4 my-3">
                    {translate('title')}
                </div>
                <MyAccountTabs />
                <hr className="border-top m-0" />
                <div className="d-flex flex-xxl-row flex-column flex-grow-1" >
                    {activeTab === 'settings' && <>
                        <div className="col-xxl-8 col-12">
                            <AccountSettings />
                        </div>
                        <div className="col-xxl-4 col-12">
                            <AccountNotice />
                        </div>
                    </>}
                    {activeTab === 'signature' && <>
                        <div className="col-xxl-8 col-12">
                            <AccountSignature />
                        </div>
                    </>}
                    {activeTab === 'personal-information' && <>
                        <div className="col-xxl-8 col-12">
                            <AccountPersonalInformation />
                        </div>
                    </>}
                </div>
                <Footer />
            </div>
        </div>
    </>;
};
