import { SignatureInfoResponse } from 'models/signature';
import { ApiRequest, Reducer } from 'store/store';
import { initialApiRequest } from 'store/store-tools';

export interface ISignatureState {
    body: ApiRequest<string>,
    info: ApiRequest<SignatureInfoResponse>,
    isSavingInfo: boolean,
}
const initialState: ISignatureState = {
    body: initialApiRequest(),
    info: initialApiRequest(),
    isSavingInfo: false,
};

const signature: Reducer<ISignatureState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SIGNATURE/FETCH_SIGNATURE_BODY':
            return {
                ...state,
                body: {
                    ...state.body,
                    isFetching: true,
                    didInvalidate: true,
                    hasError: false,
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_BODY_SUCCESS':
            return {
                ...state,
                body: {
                    ...state.body,
                    data: action.payload.signature || '',
                    isFetching: false,
                    didInvalidate: false,
                    hasError: false,
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_BODY_FAILURE':
            return {
                ...state,
                body: {
                    ...state.body,
                    isFetching: false,
                    didInvalidate: true,
                    hasError: true,
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_INFO':
            return {
                ...state,
                info: {
                    ...state.info,
                    isFetching: true,
                    didInvalidate: true,
                    hasError: false,
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_INFO_SUCCESS':
            return {
                ...state,
                info: {
                    ...state.info,
                    data: action.payload.info || undefined,
                    isFetching: false,
                    didInvalidate: false,
                    hasError: false,
                },
            };

        case '@SIGNATURE/FETCH_SIGNATURE_INFO_FAILURE':
            return {
                ...state,
                info: {
                    ...state.info,
                    isFetching: false,
                    didInvalidate: true,
                    hasError: true,
                },
            };

        case '@SIGNATURE/SAVE_SIGNATURE_INFO':
            return {
                ...state,
                isSavingInfo: true,
            };

        case '@SIGNATURE/SAVE_SIGNATURE_INFO_SUCCESS':
            return {
                ...state,
                isSavingInfo: false,
                info: {
                    ...state.info,
                    didInvalidate: true,
                    hasError: false,
                },
                body: {
                    ...state.body,
                    didInvalidate: true,
                    hasError: false,
                },
            };

        case '@SIGNATURE/SAVE_SIGNATURE_INFO_FAILURE':
            return {
                ...state,
                isSavingInfo: false,                
            };
        default:
            return state;
    }
};

export default signature;
