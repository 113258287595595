export const redirectExternalReplaceUrl = (url: string) => {
    window.location.replace(url);
};

export const reloadPage = () => {
    window.location.reload();
};

export const redirectExternalOpenNewUrl = (url?: string) => {
    window.open(url || '/', '_blank', 'noopener noreferrer');
};

export const redirectExternalCallback = (url?: string) => () => {
    redirectExternal(url);
};

export const redirectExternal = (url?: string) => {
    window.location.assign(url || '/');
};

export const ignoreRedirect = (event: React.MouseEvent<any, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
};

export const secureRedirectDomain = (
    whitelist: string[],
    url: string
): string => {
    const whiteListRegex = whitelist.map(
        (x) => new RegExp(`\\.${x.replace(/\./g, '\\.')}$`)
    );
    const returnUrl = '/';

    try {
        const isWhiteListedReturnUrl = whiteListRegex.find((match) =>
            match.test(new URL(url).hostname)
        );
        return isWhiteListedReturnUrl ? url : returnUrl;
    } catch {
        return returnUrl;
    }
};
