import { ApiRequest, Reducer } from 'store/store';
import { initialApiRequest } from 'store/store-tools';

export const initialState: {
    categories: ApiRequest<string[]>
} = {
    categories: initialApiRequest(),
} as const;

export type CategoriesState = typeof initialState;

export const CategoriesReducer: Reducer<CategoriesState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CATEGORY/FETCH_REQUEST':
            return {
                ...state,
                categories: {
                    isFetching: true,
                    didInvalidate: false,
                    hasError: false,
                },
            };
        case '@CATEGORY/FETCH_SUCCESS':
            return {
                ...state,
                categories: {
                    isFetching: false,
                    didInvalidate: false,
                    hasError: false,
                    data: action.payload,
                },
            };
        case '@CATEGORY/FETCH_FAILURE':
            return {
                ...state,
                categories: {
                    isFetching: false,
                    didInvalidate: true,
                    hasError: true,
                },
            };
        default:
            return state;
    }
};
