import { getConfiguration } from 'core/services/common/configuration/configurationLoader';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-web-analytics': {
                'site-id': string;
                'base-url'?: string;
            };
        }
    }
}

export const MatomoWidget: React.FC = () => {
    const {
        piwik: { siteId, url },
    } = getConfiguration();

    return <sgwt-web-analytics site-id={siteId} base-url={url} />;
};
