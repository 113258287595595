import classNames from 'classnames';
import { ServiceboardHeaderNavbar } from 'models/navigation';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { FeatureTracked, TopicTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';

type ServiceboardHeaderMenuNavbarProps = {
    active: ServiceboardHeaderNavbar;
};

export const ServiceboardHeaderMenuNavbar: React.FunctionComponent<ServiceboardHeaderMenuNavbarProps> = ({
    active,
}) => {
    const { t: translate } = useTranslation('catalog');
    const mediaBreakpoint = useMediaBreakpoint();
    const { trackAction } = useServiceTracking();

    const navClassName = {
        'navbar-menu-dropdown flex-column d-flex': ['xs', 'sm'].includes(mediaBreakpoint),
    };

    return (
            <Nav className={classNames('flex-nowrap ms-auto mt-1 mt-md-0 nav-tabs', navClassName)}>
                <NavItem>
                    <NavLink
                        className={
                            classNames('navbar-link ', { active: active === 'catalog' }
                            )}
                        aria-current="page"
                        tag={Link}
                        to="/catalog"
                        onClick={async () => await trackAction(FeatureTracked.OpenCatalog, { topic: TopicTracked.ServiceboardMenu })}
                    >
                        {translate('catalog:catalog')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classNames('navbar-link ', { active: active === 'myservices' })}
                        aria-current="page"
                        tag={Link}
                        to="/myservices"
                        onClick={async () => await trackAction(FeatureTracked.OpenMyServices, { topic: TopicTracked.ServiceboardMenu })}
                    >
                        {translate('catalog:myServices')}
                    </NavLink>
                </NavItem>
            </Nav>
    );
};
