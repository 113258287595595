import { toast } from 'react-toastify';
import { ToastMessage } from './ToastMessage';
import { ErrorAlert } from 'components/pages/errors/ErrorAlert';

export const toastError = (message?: string | null,
    title?: string | null,
    messageComponent?: React.ReactNode
) => toast.error(<ToastMessage
    messageOrTranslationKey={message}
    titleOrTranslationKey={title}
    messageComponent={messageComponent}
/>);

export const toastWarn = (message?: string | null,
    title?: string | null,
    messageComponent?: React.ReactNode
) => toast.warn(<ToastMessage
    messageOrTranslationKey={message}
    titleOrTranslationKey={title}
    messageComponent={messageComponent}
/>);

export const toastInfo = (message?: string | null,
    title?: string | null,
    messageComponent?: React.ReactNode
) => toast.info(<ToastMessage
    messageOrTranslationKey={message}
    titleOrTranslationKey={title}
    messageComponent={messageComponent}
/>);

export const toastSuccess = (message?: string | null,
    title?: string | null,
    messageComponent?: React.ReactNode
) => toast.success(<ToastMessage
    messageOrTranslationKey={message}
    titleOrTranslationKey={title}
    messageComponent={messageComponent}
/>);

export interface IToasterUsingTranslationParam {
    messageTranslationKey?: string | null,
    titleTranslationKey?: string | null,
    messageComponent?: React.ReactNode,
    messageTranslationOptions?: { [key: string]: string },
    titleTranslationOptions?: { [key: string]: string },
}

export const toastUsingTranslationWarn = (param: IToasterUsingTranslationParam) => toast.warn(<ToastMessage
    messageOrTranslationKey={param.messageTranslationKey}
    titleOrTranslationKey={param.titleTranslationKey}
    messageComponent={param.messageComponent}
    messageTranslationOptions={param.messageTranslationOptions}
    titleTranslationOptions={param.titleTranslationOptions}
/>);

export const toastUsingTranslationInfo = (param: IToasterUsingTranslationParam) => toast.info(<ToastMessage
    messageOrTranslationKey={param.messageTranslationKey}
    titleOrTranslationKey={param.titleTranslationKey}
    messageComponent={param.messageComponent}
    messageTranslationOptions={param.messageTranslationOptions}
    titleTranslationOptions={param.titleTranslationOptions}
/>);

export const toastUsingTranslationSuccess = (param: IToasterUsingTranslationParam) => toast.success(<ToastMessage
    messageOrTranslationKey={param.messageTranslationKey}
    titleOrTranslationKey={param.titleTranslationKey}
    messageComponent={param.messageComponent}
    messageTranslationOptions={param.messageTranslationOptions}
    titleTranslationOptions={param.titleTranslationOptions}
/>);

export const toastUsingTranslationError = (param: Omit<IToasterUsingTranslationParam, 'messageComponent'>) => toast.error(<ToastMessage
    titleOrTranslationKey={param.titleTranslationKey ?? 'tooltip:errorAlertTitle'}
    titleTranslationOptions={param.titleTranslationOptions}
    messageComponent={<ErrorAlert messageTranslationKey={param.messageTranslationKey} messageTranslationOptions={param.messageTranslationOptions} />}
/>);

export interface IToaster {
    toastUsingTranslationSuccess(param: IToasterUsingTranslationParam): void;
    toastUsingTranslationError(param: IToasterUsingTranslationParam): void;
}

export class Toaster implements IToaster {
    public toastUsingTranslationSuccess(param: Omit<IToasterUsingTranslationParam, 'messageComponent'>): void {
        toastUsingTranslationSuccess(param);
    }

    public toastUsingTranslationError(param: Omit<IToasterUsingTranslationParam, 'messageComponent'>): void {
        toastUsingTranslationError(param);
    }
}