import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { CatalogNavBar } from './CatalogNavBar';
import { useDispatch } from 'react-redux';
import { Loading } from 'components/common/loading/Loading';
import { getBoardServices } from 'core/services/serviceBoardService/serviceBoardService';
import { getUserBookmarks } from 'core/services/bookmark/userBookmarkService';
import { AccessType } from 'models/accessType';
import { useBoardServices } from 'components/hooks/services/useBoardServices';
import { getCategories } from 'store/Categories/categoriesThunk';
import { LanguageEnFrOnly } from 'components/hooks/LanguageProvider';
import { getRecommendations } from 'core/services/recommendations/recommendedServices';
import { getMyHighlightLayouts } from 'core/services/highlightLayout/highlightLayoutService';

export const CatalogPage = () => {
    const dispatch = useDispatch();
    const { t: translate, i18n: { language } } = useTranslation('service-list');
    const { services, loading } = useBoardServices(AccessType.BothForCatalog);

    useEffect(() => {
        dispatch<any>(getBoardServices(AccessType.BothForCatalog, language, 'nonSgmServices'));
        dispatch<any>(getUserBookmarks());
        dispatch<any>(getCategories());
        dispatch<any>(getRecommendations());
        dispatch<any>(getMyHighlightLayouts(language));
    }, [dispatch, language]);

    useEffect(() => {
        document.title = 'SG Markets - ' + translate('catalog:catalog');
    }, [translate, language]);

    const showLoader = !services?.length || loading;

    return (
        <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3 d-flex flex-grow-1 flex-column">
            <LanguageEnFrOnly />
            {showLoader && <Loading />}
            {!showLoader && <>
                <CatalogNavBar />
                <Outlet />
            </>}
        </div>
    );
};