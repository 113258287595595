import { BannerSectionsResponse } from 'models/banner';
import { ApiRequest, Reducer } from 'store/store';
import { initialApiRequest, mergeRecords } from 'store/store-tools';

export interface IBannerState {
    list: ApiRequest<BannerSectionsResponse>,
    templates: Record<string, ApiRequest<string>>,
}
export const initialState: IBannerState = {
    list: initialApiRequest(),
    templates: {},
};

const banner: Reducer<IBannerState> = (state = initialState, action) => {
    switch (action.type) {
        case '@BANNER/FETCH_BANNERS':
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: true,
                    didInvalidate: true,
                    hasError: false,
                },
            };

        case '@BANNER/FETCH_BANNERS_SUCCESS':
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload.bannerSections || [],
                    isFetching: false,
                    didInvalidate: false,
                    hasError: false,
                },
            };

        case '@BANNER/FETCH_BANNERS_FAILURE':
            return {
                ...state,
                list: {
                    ...state.list,
                    isFetching: false,
                    didInvalidate: true,
                    hasError: true,
                },
            };

        case '@BANNER/FETCH_BANNER_TEMPLATE':
            return {
                ...state,
                templates: mergeRecords(
                    state.templates,
                    action.payload.templateUrl,
                    {
                        isFetching: true,
                        didInvalidate: true,
                        hasError: false,
                    }),
            };

        case '@BANNER/FETCH_BANNER_TEMPLATE_SUCCESS':
            return {
                ...state,
                templates: mergeRecords(
                    state.templates,
                    action.payload.templateUrl,
                    {
                        data: action.payload.templateContent || '',
                        isFetching: false,
                        didInvalidate: false,
                        hasError: false,
                    }),
            };

        case '@BANNER/FETCH_BANNER_TEMPLATE_FAILURE':
            return {
                ...state,
                templates: mergeRecords(
                    state.templates,
                    action.payload.templateUrl,
                    {
                        isFetching: false,
                        didInvalidate: true,
                        hasError: true,
                    }),
            };

        default:
            return state;
    }
};

export default banner;
