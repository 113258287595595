import { useAllMyServices } from 'components/hooks/services/useAllMyServices';
import { useEssentialServices } from 'components/hooks/services/useEssentialServices';
import { ignoreRedirect } from 'core/services/redirectService';
import { scrollUp } from 'core/utils/animations';
import { EXTERNAL_LINK_PROPS } from 'core/utils/link';
import { EssentialServiceModel } from 'models/essentialService';
import { map } from 'lodash-es';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownDetails } from '../../components/details/DetailsDropdown';
import { NoServices } from '../NoServices';
import { EssentialsNavBarLink } from './EssentialsNavBar';
import { TopicTracked, ServiceTracked, FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import {
    ServiceTrackingInfoProvider,
    ActionTrackingInfoProvider,
} from 'components/common/tracking/serviceTrackingContext';
import { BookmarkOrigin } from 'models/bookmark';

import './EssentialsLayout.scss';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';

export const EssentialsLayout = () => {
    const { essentialServices } = useEssentialServices();
    const { showAllMyServicesTab } = useAllMyServices();

    if (!essentialServices || essentialServices.length === 0) {
        return <NoServices />;
    }

    return (
        <div className="container-xl ps-3 px-md-4 px-lg-64px px-xl-5 px-xxl-3 flex-grow-1">
            <div className="mb-4">
                <div
                    id="essentials_card"
                    className="mb-5 d-none d-lg-block"
                >
                    <div className="row row-cols-4 px-1">
                        {map(essentialServices, (service, i) => (
                            <EssentialsCard key={i} service={service} />
                        ))}
                        {showAllMyServicesTab && <SeeAllServicesCard />}
                    </div>
                </div>
                <div
                    id="essentials_icon"
                    className="mb-5 d-block d-lg-none"
                >
                    <div className="row row-cols-4 row-cols-sm-5 row-cols-md-6 px-0 g-0">
                        {map(essentialServices, (service, i) => (
                            <EssentialsIcon key={i} service={service} />
                        ))}
                        {showAllMyServicesTab && <SeeAllServicesIcon />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const SeeAllServicesCard = () => {
    const { t: translate } = useTranslation('service-list');
    return (
        <ActionTrackingInfoProvider
            actionTrackedInfo={{ topic: TopicTracked.Essentials }}
        >
            <div className="col px-2 mb-3">
                <EssentialsNavBarLink
                    to="all-services"
                    className="d-block card border card-raising h-100 pointer"
                    style={{
                        borderRadius: '4px',
                        height: '170px',
                    }}
                >
                    <div
                        className="card-body align-items-center d-flex flex-column justify-content-center"
                        style={{ height: '128px' }}
                    >
                        <div
                            style={{ width: '64px', height: '64px' }}
                            className="d-flex align-items-center justify-content-center bg-opacity-20 bg-secondary rounded-circle"
                        >
                            <i className="icon icon-lg text-secondary">list</i>
                        </div>
                    </div>
                    <div className="card-footer p-0">
                        <p className="text-secondary text-center mb-0">
                            {translate('service-list:seeAllMyServices')}
                        </p>
                    </div>
                </EssentialsNavBarLink>
            </div>
        </ActionTrackingInfoProvider>
    );
};

export const SeeAllServicesIcon = () => {
    const { t: translate } = useTranslation('service-list');

    return (
        <CommonIcon
            to="/myservices/all-services"
            title={translate('service-list:seeAllMyServices')}
        >
            <span className="text-black fw-medium rounded-circle essentials-icon-list p-3 bg-lvl3">
                <i className="icon">list</i>
            </span>
        </CommonIcon>
    );
};

const EssentialsCard = ({ service }: { service: EssentialServiceModel }) => {
    const { trackService } = useServiceTracking();
    const { hasOnlyB2b2cAccess } = useApplicationContext();
    const serviceTracked: ServiceTracked = {
        topic: TopicTracked.Essentials,
        service,
        origin: BookmarkOrigin.SgmService,
    };

    return (
        <ServiceTrackingInfoProvider serviceTracked={serviceTracked}>
            <div className="col mb-3 px-2">
                <a
                    href={service.Url || undefined}
                    onClick={async () =>
                        await trackService(
                            FeatureTracked.OpenService,
                            serviceTracked
                        )
                    }
                    className="text-primary text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div
                        className="card border card-raising h-100 pointer"
                        style={{ borderRadius: '4px' }}
                    >
                        <div
                            className="card-body p-3 essentials-card-body"
                            style={{
                                backgroundImage: `linear-gradient(0deg, rgba(81,87,87,0)50%, rgba(81,87,87,.9)), url('${service.ImgUrl}')`,
                            }}
                        >
                            <div className="card shadow d-flex justify-content-center align-items-center essentials-card-icon text-black bg-white p-0 display-5 ">
                                <span className="fw-medium">
                                    {service.Initials}
                                </span>
                            </div>
                        </div>
                        <div
                            className="card-footer d-flex align-items-center justify-content-between p-0 border-top bg-opacity-10 bg-secondary"
                            style={{ borderRadius: '0px 0px 2px 2px' }}
                        >
                            <label
                                className="essentials-card-title fw-medium text-truncate ms-2 ms-lg-3 mb-0"
                                style={{ lineHeight: '38px' }}
                            >
                                {service.Title}
                            </label>
                            {!hasOnlyB2b2cAccess && (
                                <DropdownDetails
                                    serviceKey={service.ServiceKey}
                                    bookmarkOrigin={BookmarkOrigin.SgmService}
                                    onToggle={ignoreRedirect}
                                />
                            )}
                        </div>
                    </div>
                </a>
            </div>
        </ServiceTrackingInfoProvider>
    );
};

const EssentialsIcon = ({ service }: { service: EssentialServiceModel }) => {
    return (
        <CommonIcon
            to={service.Url || '/'}
            title={service.Title || ''}
            isExternal={true}
        >
            <span className="display-4 text-black fw-medium">
                {service.Initials}
            </span>
        </CommonIcon>
    );
};

type CommonIconProps = {
    to: string;
    title: string;
    isExternal?: boolean;
};
const CommonIcon: FunctionComponent<PropsWithChildren<CommonIconProps>> = ({
    to,
    title,
    isExternal,
    children,
}) => (
    <div className="col">
        <a
            className="card card-filling-secondary h-100 pointer"
            href={to}
            {...(isExternal && EXTERNAL_LINK_PROPS)}
            onClick={scrollUp}
        >
            <div className="card-body d-flex align-items-center justify-content-center pt-2 pt-md-3 pb-2 px-0">
                <div className="card card-raising essentials-card-square d-flex justify-content-center align-items-center p-0 bg-white border">
                    {children}
                </div>
            </div>
            <div className="card-footer text-center p-0">
                <label className="text-primary fw-medium essentials-icon-label">
                    {title}
                </label>
            </div>
        </a>
    </div>
);
