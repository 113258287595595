import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificationListFilter } from 'components/hooks/Notification/useNotificationListFilter';
import { doMarkAsReadAll } from 'store/Notification/notificationThunk';
import { AppState } from 'store/store';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';

export const DismissAllButton = () => {
    const { producerCodeActive, isAllProducer } = useNotificationListFilter();
    const { t: translate } = useTranslation('my-notification');
    const dispatch = useDispatch();
    const { isFetching } = useSelector((state: AppState) => state.notification.markAsRead);
    const mediaBreakpoint = useMediaBreakpoint();

    const handleDismissAll = async (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        const selectedProducerCode = isAllProducer(producerCodeActive) ? null : producerCodeActive;
            await dispatch<any>(doMarkAsReadAll(selectedProducerCode));
    };

    const btnClass = ['xs', 'sm'].includes(mediaBreakpoint)
        ? 'btn btn-flat text-nowrap'
        : 'btn btn-discreet-secondary btn-lg my-auto text-nowrap';

    return (
        <>
            <button type="button" className={btnClass} onClick={handleDismissAll} disabled={isFetching}>
                {translate('sortDismissedAll')} {isFetching && (<div className="spinner spinner-sm text-info ms-2"></div>)}
            </button>
        </>
    );
};
