import { combineReducers } from 'redux';
import {
    IntroTourFetchSuccessActionType,
    IntroTourFetchRequestActionType,
    IntroTourFetchFailureActionType,
} from '../actions/introTourAction';
import { ActionType } from 'store/actions/ActionType';
import { Options } from 'intro.js/src/option';

const introTour = (
    state: Nullable<Options> = null,
    action: ActionType
) => {
    if (action.type === IntroTourFetchSuccessActionType) {
        return action.payload;
    }
    return state;
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case IntroTourFetchRequestActionType: {
            return true;
        }
        case IntroTourFetchFailureActionType:
        case IntroTourFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    introTour,
    isLoading,
});
