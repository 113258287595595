import { MarkAsClickedResponse, NotificationCountByProducerResponse, SearchNotificationsResponse } from 'services/ApiService/Notification/NotificationApiClient';

// Notification Tracking click
export const TrackUserNotificationClickFetchRequestActionType = '@NOTIFICATION/TRACK_USER_CLICK_FETCH_REQUEST';
export const TrackUserNotificationClickFetchSuccessActionType = '@NOTIFICATION/TRACK_USER_CLICK_FETCH_SUCCESS';
export const TrackUserNotificationClickFetchFailureActionType = '@NOTIFICATION/TRACK_USER_CLICK_FETCH_FAILURE';

export const createTrackUserNotificationClickFetchRequestAction = () => ({
    type: TrackUserNotificationClickFetchRequestActionType,
} as const);

export const createTrackUserNotificationClickFetchSuccessAction = (markAsClickedResponseModel: MarkAsClickedResponse) => ({
    type: TrackUserNotificationClickFetchSuccessActionType,
    payload: markAsClickedResponseModel,
} as const);

export const createTrackUserNotificationClickFetchFailureAction = () => ({
    type: TrackUserNotificationClickFetchFailureActionType,
} as const);

// Notification Count By Producers
export const NotificationCountByProducersFetchRequestActionType = '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_REQUEST';
export const NotificationCountByProducersFetchSuccessActionType = '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_SUCCESS';
export const NotificationCountByProducersFetchFailureActionType = '@NOTIFICATION/COUNT_BY_PRODUCERS_FETCH_FAILURE';

export const createNotificationCountByProducersFetchRequestAction = () => ({
    type: NotificationCountByProducersFetchRequestActionType,
} as const);

export const createNotificationCountByProducersFetchSuccessAction = (countByProducers: NotificationCountByProducerResponse[] | undefined) => ({
    type: NotificationCountByProducersFetchSuccessActionType,
    payload: countByProducers,
} as const);

export const createNotificationCountByProducersFetchFailureAction = () => ({
    type: NotificationCountByProducersFetchFailureActionType,
} as const);

// Notification Count By Producers
export const SearchNotificationsFetchRequestActionType = '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_REQUEST';
export const SearchNotificationsFetchSuccessActionType = '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_SUCCESS';
export const SearchNotificationsFetchFailureActionType = '@NOTIFICATION/SEARCH_NOTIFICATIONS_FETCH_FAILURE';

export const createSearchNotificationsFetchRequestAction = () => ({
    type: SearchNotificationsFetchRequestActionType,
} as const);

export const createSearchNotificationsFetchSuccessAction = (searchResult: SearchNotificationsResponse) => ({
    type: SearchNotificationsFetchSuccessActionType,
    payload: searchResult,
} as const);

export const createSearchNotificationsFetchFailureAction = () => ({
    type: SearchNotificationsFetchFailureActionType,
} as const);

// Notification Tracking unread
export const TrackUserNotificationReadFetchRequestActionType = '@NOTIFICATION/TRACK_USER_READ_FETCH_REQUEST';
export const TrackUserNotificationReadFetchSuccessActionType = '@NOTIFICATION/TRACK_USER_READ_FETCH_SUCCESS';
export const TrackUserNotificationReadFetchFailureActionType = '@NOTIFICATION/TRACK_USER_READ_FETCH_FAILURE';
export const MarkAsReadFetchInvalidateActionType = '@NOTIFICATION/MARK_AS_READ_FETCH_INVALIDATE';

export const createTrackUserNotificationReadFetchRequestAction = () => ({
    type: TrackUserNotificationReadFetchRequestActionType,
} as const);

export const createTrackUserNotificationReadFetchSuccessAction = () => ({
    type: TrackUserNotificationReadFetchSuccessActionType,
} as const);

export const createTrackUserNotificationReadFetchFailureAction = () => ({
    type: TrackUserNotificationReadFetchFailureActionType,
} as const);

// NotificationAction
export type SgmNotificationAction =
    | ReturnType<typeof createTrackUserNotificationClickFetchRequestAction>
    | ReturnType<typeof createTrackUserNotificationClickFetchSuccessAction>
    | ReturnType<typeof createTrackUserNotificationClickFetchFailureAction>
    | ReturnType<typeof createNotificationCountByProducersFetchRequestAction>
    | ReturnType<typeof createNotificationCountByProducersFetchSuccessAction>
    | ReturnType<typeof createNotificationCountByProducersFetchFailureAction>
    | ReturnType<typeof createSearchNotificationsFetchRequestAction>
    | ReturnType<typeof createSearchNotificationsFetchSuccessAction>
    | ReturnType<typeof createSearchNotificationsFetchFailureAction>    
    | ReturnType<typeof createTrackUserNotificationReadFetchRequestAction>
    | ReturnType<typeof createTrackUserNotificationReadFetchSuccessAction>
    | ReturnType<typeof createTrackUserNotificationReadFetchFailureAction>;
