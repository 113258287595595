import { openHelpCenterForm } from 'components/layout/helpCenter/HelpCenter';
import { FC } from 'react';
import { getI18n, useTranslation } from 'react-i18next';

interface IErrorAlertProps {
    messageTranslationKey?: string | null,
    messageTranslationOptions?: { [key: string]: string };
}
export const ErrorAlert: FC<IErrorAlertProps> = ({ messageTranslationKey, messageTranslationOptions }) => {
    const i18n = getI18n();
    const { t: translate } = useTranslation(Object.keys(i18n.store.data.en));
    const messageTranslated = translate(messageTranslationKey ?? '', messageTranslationOptions);
    
    return (
        <>
            {messageTranslated && <div>{messageTranslated}</div>}
            <div>
            <span className="me-1">{translate('tooltip:errorAlertButtomMessage1')}</span>
                <button className="btn btn-link text-decoration-underline p-0 m-0" onClick={openHelpCenterForm}>{translate('tooltip:errorAlertButtomMessage2')}</button>
                <span className="ms-1">{translate('tooltip:errorAlertButtomMessage3')}</span>
            </div>
        </>
    );
};
