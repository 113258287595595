import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getCountByProducers } from 'store/Notification/notificationThunk';
import { NotificationCountByProducerResponse, ProducerUniverse } from 'services/ApiService/Notification/NotificationApiClient';
import classNames from 'classnames';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { SkeletonWrapper } from 'components/common/SkeletonWrapper';
import Skeleton from 'react-loading-skeleton';
import { PRODUCER_CODE_ALL_KEY } from 'components/hooks/Notification/useNotificationListFilter';
import { useNotificationCountByProducer } from 'components/hooks/Notification/useNotificationCountByProducer';
import { ProducerCountItem, ProducerCountItems } from './ProducerCountItems';
import { NotificationSettingsLink } from '../NotificationSettingsLink';
import { useNotificationTitle } from 'components/hooks/Notification/useNotificationTitle';

import './ProducerMenu.scss';

export const ProducerMenu: FC = () => {
    const { t: translate } = useTranslation('my-notification');
    const { countByProducers: { isFetching, didInvalidate, data }, allUnreadTotalCount } = useNotificationCountByProducer();
    const dispatch = useDispatch();
    const mediaBreakpoint = useMediaBreakpoint();
    const { title } = useNotificationTitle();

    useEffect(() => {
        dispatch<any>(getCountByProducers());
    }, [didInvalidate]);


    if (isFetching || !data) {
        return <div className="d-flex flex-column flex-grow-1 bg-lvl2"><NotificationProducersSkeleton /></div>;
    }

    const dataWithProducer = data?.filter(d => !!d?.producer?.code) || [];
    if (dataWithProducer.length == 0) {
        return false;
    }

    const sgmUniverseCountByProducers: NotificationCountByProducerResponse[] = dataWithProducer.filter(c => c.producer!.universe === ProducerUniverse.SgMarkets) || [];
    const sgUniverseCountByProducers = dataWithProducer.filter(c => c.producer!.universe === ProducerUniverse.SocieteGenerale) || [];

    const allLabelTranslated = translate('sortAll');
    const allProducerCountItem: NotificationCountByProducerResponse = {
        unreadCount: allUnreadTotalCount,
        producer: {
            code: PRODUCER_CODE_ALL_KEY,
            name: allLabelTranslated,
            internalOnly: true,
            universe: ProducerUniverse.SgMarkets,
        },
    };

    const collapseBlockClass = (): string => {
        if (['xs', 'sm', 'md'].includes(mediaBreakpoint)) {
            return 'collapse ';
        }
        return '';
    };

    const collapseContentBlockClass = (): string => {
        if (['xs', 'sm', 'md'].includes(mediaBreakpoint)) {
            return 'collapse-content';
        }
        return '';
    };

    const countWrapperClass = (): string => {
        let classes = 'd-flex flex-md-column flex-grow-1 bg-lvl2 producer-menu-wrapper';
        if (['xs', 'sm'].includes(mediaBreakpoint)) {
            classes += ' count-panel-cover';
        }
        return classNames(classes);
    };

    return (
        <div className={countWrapperClass()} style={{ zIndex: 10 }} >
            <div className="d-flex">
                <button
                    className="d-block d-lg-none btn btn-flat btn-lg" id="countMenuBtnId"
                    type="button" data-bs-toggle="collapse" data-bs-target="#countMenuId" aria-expanded="false" aria-controls="countMenuId"
                >
                    <i className="icon active">menu</i>
                </button>
                <div className="d-block d-md-none fw-medium ms-2 my-auto">{title}</div>
            </div>

            <div className={collapseBlockClass()} id="countMenuId">
                <div className={collapseContentBlockClass()}>
                    <div className="fw-medium notif-custom-space-b-12px ms-3 mt-4">{translate('producerCountTitle')}</div>
                    <div>
                        <ul className="list-group">
                            <ProducerCountItem countByProducer={allProducerCountItem} />
                            {sgmUniverseCountByProducers.length > 0 && <ProducerCountItems countByProducers={sgmUniverseCountByProducers} />}
                            {sgUniverseCountByProducers.length > 0 && <ProducerCountItems countByProducers={sgUniverseCountByProducers} showTopSeparator={sgmUniverseCountByProducers.length > 0} />}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-flex my-auto mt-md-auto mb-md-1 ms-auto ms-md-3 settings-display sticky-bottom">
                <NotificationSettingsLink />
            </div>
        </div>
    );
};

const NotificationProducersSkeleton = () => {
    return (
        <>
            <div className="d-none d-lg-block m-3">
                <SkeletonWrapper>
                    <Skeleton height={20} width={115} className="mb-1" />
                    <Skeleton height={20} className="mb-1" count={3} />
                    <Skeleton height={20} className="mb-1" />
                    <hr />
                    <Skeleton height={30} className="mb-1" count={2} />
                </SkeletonWrapper>
            </div>
            <div className="d-block d-lg-none m-3">
                <SkeletonWrapper>
                    <Skeleton height={30} className="m-auto" />
                </SkeletonWrapper>
            </div>
        </>
    );
};