import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const NotificationSettingsLink: FC = () => {
    const { t: translate } = useTranslation('my-notification');
    const ariaTextIcon = translate('ariaTextOpenInNewTab');
    
    return (
        <Link className="btn btn-flat-secondary btn-icon-start mb-md-2"
            to="/myaccount/settings"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={ariaTextIcon} 
            title={ariaTextIcon}
        >
            <i className="icon d-none d-lg-inline-block">open_in_new</i>
            <i className="icon d-lg-none" aria-label={ariaTextIcon} title={ariaTextIcon}>settings</i>
            <span className="ms-1 d-none d-lg-block">{translate('linkNotificationSettings')}</span>
        </Link>
    );
};