import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchItemResults } from 'components/hooks/search/useSearchItemResults';
import { clipPaginateList } from './clip-list';
import { SeeMoreLinkSgm } from './SeeMoreLink';
import { SearchResultType } from 'services/ApiService/SgmSearch/SgmSearchApiClient';
import { ServiceSearchItemCompactList, getSgmItemRequestAccessInfo } from '../myServicesPage/components/search/ServiceSearchItemCompactList';
import { EmptyLinePlaceholder } from 'components/common/EmptyLinePlaceholder';
import { useSearchQuery } from 'components/hooks/search/useSearchQuery';

type SearchSgmResultsProps = {
    limit?: number;
};

const RemoveLabelButton = (props: { text: string | undefined; onClick: () => void }) => (
    <button
        type="button"
        onClick={props.onClick}
        className="badge badge-dismissible badge-info m-1"
    >
        <em className="icon icon-sm">close</em>
        {props.text}
    </button>
);

export const SearchSgmResults: FC<SearchSgmResultsProps> = ({ limit }) => {
    const { t: translate } = useTranslation('service-list');
    const {
        selectedCategories,
        selectedSubcategories,
        removeCategory,
        removeSubcategory,
    } = useSearchQuery();

    return (
        <div className="mb-5">
            <h2 className="h6 text-secondary py-2 mb-2">
                {translate('service-list:sgmServices')}
            </h2>
            {selectedCategories.length > 0 ||
                selectedSubcategories.length > 0 ? (
                <div className="mb-4">
                    {selectedCategories.map((e) => (
                        <RemoveLabelButton
                            onClick={() => removeCategory(e.key || '')}
                            text={e.label}
                            key={e.key}
                        />
                    ))}
                    {selectedSubcategories.map((e) => (
                        <RemoveLabelButton
                            onClick={() => removeSubcategory(e.key || '')}
                            text={e.label}
                            key={e.key}
                        />
                    ))}
                </div>
            ) : null}

            <ResultList limit={limit} />
        </div>
    );
};

const ResultList: FC<SearchSgmResultsProps> = ({ limit }) => {
    const { t: translate } = useTranslation('service-list');
    const { sgm: { data, isFetching } } = useSearchItemResults();

    const { clipped, total, hasMore } = clipPaginateList(data, limit);
    if (isFetching) {
        return (
            <div className="mb-5">
                <EmptyLinePlaceholder count={limit} />
            </div>
        );
    }

    if (clipped.length === 0) {
        return (
            <p className="h6 text-secondary fw-normal">
                {translate('service-list:noResultsFound')}
            </p>
        );
    }

    return (
        <>
            <ServiceSearchItemCompactList
                serviceSearchItemLineInfos={clipped.map((sgmItem) => {
                    return {
                        referenceId: sgmItem.referenceId,
                        title: sgmItem.title,
                        url: sgmItem.url,
                        category: sgmItem.category,
                        initials: sgmItem.initials,
                        isInternal: sgmItem.isInternal,
                        searchItemSource: sgmItem.sourceInfos?.at(0)?.source,
                        searchType: SearchResultType.SgmItem,
                        requestAccessInfo: getSgmItemRequestAccessInfo(sgmItem),
                        description: sgmItem.description,
                        relatedToRelationType: sgmItem.sourceInfos?.at(0)?.relatedTo?.relationType,
                    };
                })}
            />
            {hasMore && <SeeMoreLinkSgm total={total} />}
        </>
    );
};
