import { Dispatch } from 'react';
import { ActionType } from 'store/actions/ActionType';
import { ThunkResult } from 'store/store';
import {
    createFetchSignatureBodyAction,
    createFetchSignatureBodySuccessAction,
    createFetchSignatureBodyFailureAction,
    createFetchSignatureInfoAction,
    createFetchSignatureInfoSuccessAction,
    createFetchSignatureInfoFailureAction,
    createSaveSignatureInfoAction,
    createSaveSignatureInfoSuccessAction,
    createSaveSignatureInfoFailureAction,
} from 'store/actions/signatureAction';
import { SignatureInfoResponse } from 'models/signature';

export const getSignatureBody = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { serviceBoardClient, logger }
    ) => {
        const { signature: { body: request } } = state();

        if (request?.isFetching || !request?.didInvalidate) {
            return;
        }

        dispatch(createFetchSignatureBodyAction());
        try {
            const signature = await serviceBoardClient.getSignatureBody();
            dispatch(createFetchSignatureBodySuccessAction(signature));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createFetchSignatureBodyFailureAction());
        }
    };
};

export const getSignatureInfo = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { serviceBoardClient, logger }
    ) => {
        const { signature: { info: request } } = state();

        if (request?.isFetching || !request?.didInvalidate) {
            return;
        }

        dispatch(createFetchSignatureInfoAction());
        try {
            const info = await serviceBoardClient.getSignatureInfo();
            dispatch(createFetchSignatureInfoSuccessAction(info));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createFetchSignatureInfoFailureAction());
        }
    };
};

export const saveSignatureInfo = (signatureInfo: SignatureInfoResponse): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { serviceBoardClient, logger, toaster }
    ) => {
        const { signature: { isSavingInfo } } = state();

        if (isSavingInfo) {
            return;
        }

        dispatch(createSaveSignatureInfoAction());
        try {            
            await serviceBoardClient.saveSignatureInfo(signatureInfo);
            
            toaster.toastUsingTranslationSuccess({messageTranslationKey: 'signature:saveSuccesfull'});

            dispatch(createSaveSignatureInfoSuccessAction());
        } catch (error: any) {
            toaster.toastUsingTranslationError({messageTranslationKey: 'signature:saveError'});
            logger.error(error.message, error);
            dispatch(createSaveSignatureInfoFailureAction());
        }
    };
};
